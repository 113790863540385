import _ from 'lodash'
import moment from 'moment'
import {stripHtml} from "@/shared/custom";
import {first, omit, orderBy, sortBy,last} from "lodash";
import{ checkType, createRule} from "@/shared/custom"
const calculateFields = [
    {
        pageName : 'insured_details',
        fields : ['insuredDetails__fraudCommittedBy']
    },
]

function isJSON(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
    
}

const disabledCheck = (payload, fieldGroup, field) => {
    if(payload == "READ_WRITE" && fieldGroup == 'READ_WRITE' && field == 'READ_WRITE'){
        return false
    }
    else{
        return true
    }
}

const processFrom = async(state, payload, stateName) =>{
    
    console.log('Entered in processForm function');
    let case_form_data = []
    state.validate_count_sections = []
    if(payload && payload.length>0){
        let tempPageFields = first(payload)
        let _data = [];  
        let sub_field_list = [];      
         
        if(tempPageFields && tempPageFields.formPageFieldGroups && tempPageFields.formPageFieldGroups.length){
            let tempGroupSection = tempPageFields.formPageFieldGroups;
            tempGroupSection =  sortBy(tempGroupSection, function (obj) {
                return parseInt((obj.orderNo) ? obj.orderNo : 0);
            });     
            tempGroupSection = orderBy(tempGroupSection, ['orderNo'],['asc'])   
            await Promise.all(tempGroupSection.map(async (investigation_page_fields, investigation_page_index)=>{
                
                let maindata = (investigation_page_fields.attributes && investigation_page_fields.attributes.startsWith("{") && isJSON(investigation_page_fields.attributes)) 
                ?   JSON.parse(investigation_page_fields.attributes)?.type
                    ? JSON.parse(investigation_page_fields.attributes)
                            : {      
                              "type": "single"
                              }  
                                : {      
                            "type": "single"
                                  }  
                let tempFieldsForm = {
                    header :{},
                    body:{},
                    tabelHeader: [],
                }
                let fieldList = [];
                tempFieldsForm.header = omit(investigation_page_fields, ['fields']);
                tempFieldsForm.header['type'] = maindata.type
                tempFieldsForm.header['validate'] = true
                tempFieldsForm.header['roles'] = []
                tempFieldsForm.header['validateCount'] = []
                tempFieldsForm.header['addRightsRole'] = []
                tempFieldsForm.header['girdEditRightsRoles'] = []
                tempFieldsForm.header['visibleRightsRole'] = []
                tempFieldsForm.header['visibleButton'] = []
                tempFieldsForm.header['custome_button'] = false;
                tempFieldsForm.header['default_button'] = false;
                tempFieldsForm.header['custome_button_values'] = [];
                tempFieldsForm.header['is_workflow'] = false;
                tempFieldsForm.header['sectionObj'] = ''
                tempFieldsForm.header['tabelHeader'] = []
                tempFieldsForm.header['check_display'] = 'true';
                tempFieldsForm.header['button_display'] = 'true';
                

                if(investigation_page_fields?.attributes?.includes('additionalAttributes')){
                    let value = JSON.parse(investigation_page_fields?.attributes)?.additionalAttributes;
                    tempFieldsForm.header['additionalAttributes'] = typeof value === 'string'? JSON.parse(value) : value;
                }

                if(maindata && maindata.roleCode){
                    tempFieldsForm.header['roles'] = maindata.roleCode;
                }
                if(maindata && maindata.addRightsRole){
                    tempFieldsForm.header['addRightsRole'] = maindata.addRightsRole;
                }
                if(maindata && maindata.visibleRightsRole){
                    tempFieldsForm.header['visibleRightsRole'] = maindata.visibleRightsRole;
                }
                if(maindata && maindata.visibleButton){
                    tempFieldsForm.header['visibleButton'] = maindata.visibleButton;
                }
                if(maindata && maindata.girdEditRightsRoles){
                    tempFieldsForm.header['girdEditRightsRoles'] = maindata.girdEditRightsRoles;
                }
                if(maindata && maindata.sendPayload){
                    tempFieldsForm.header['sendPayload'] = maindata.sendPayload;
                }
                if(maindata && maindata.obj_name){
                    tempFieldsForm.header['sectionObj'] = maindata.obj_name;
                }
                if(maindata && maindata.fetch_data_table){
                    tempFieldsForm.header['fetchDataTable'] = maindata.fetch_data_table;
                }
                if(maindata && maindata.custome_button){
                    tempFieldsForm.header['custome_button'] = maindata.custome_button;
                }
                if(maindata && maindata.custome_button_values){
                    tempFieldsForm.header['custome_buttons'] = maindata.custome_button_values;
                }
                if(maindata && maindata.is_workflow==true){
                    tempFieldsForm.header['is_workflow'] = true;
                }

                if(maindata && maindata.custome_button_action_id){
                    tempFieldsForm.header['custome_button_action_id'] = maindata.custome_button_action_id;
                }

                if(maindata && maindata.validate_count){
                    state.validate_count_sections.push(tempFieldsForm.header.sectionObj)
                }

                
                if(maindata && maindata.default_button){
                    tempFieldsForm.header['default_button'] = maindata.default_button;
                }
                if(maindata && maindata.buttonCode){
                    tempFieldsForm.header['table_code'] = maindata.buttonCode;
                }
                tempFieldsForm.header.model = (tempFieldsForm.header.displayName)
                if(investigation_page_fields){
                    let _temp_data = {...investigation_page_fields};
                    if (typeof _temp_data !== 'undefined' && _temp_data.formPageFields && _temp_data.formPageFields.length){
                        state.current_page_details = omit(_temp_data,  ['formPageFieldGroups'])
                        let _temp_sections = _temp_data.formPageFields; 
                        _temp_sections =  sortBy(_temp_sections, function (obj) {
                            return parseInt((obj.orderNo) ? obj.orderNo : 0);
                        }); 
                        if(_temp_sections && _temp_sections.length>=1){
                            _temp_sections.forEach((list, index)=>{
                                let data = (list && list.attributes && isJSON(list.attributes)) ? JSON.parse(list.attributes) : {
                                    "label_value":"6",        
                                    "type": "text",
                                    "columnClass": "",
                                }  

                                
                                // let fieldStatusCheck = FieldStatusCheck(tempGroupSection, list.authorities, {}, list.authorityCode); 
                                if(tempPageFields.authorities == null || tempPageFields.authorities == undefined){
                                    tempPageFields.authorities = {"authorityCode": "READ_WRITE"}
                                }
                                if(_temp_data.authorityCode == null || _temp_data.authorityCode == undefined){
                                    _temp_data.authorityCode = "READ_WRITE"
                                }
                                let fieldStatusCheck = disabledCheck(tempPageFields.authorities.authorityCode, _temp_data.authorityCode, list.authorityCode)  
                                state.customeButton = fieldStatusCheck
                                if(data && !data.addField){
                                    data.addField = false;
                                } 
                                else {
                                    data.addField = true;
                                }

                                if(data && !data.girdEdit){
                                    data.girdEdit = false;
                                } 
                                    
                                if(tempFieldsForm.header.type=='multiple'){
                                    if(!data.columnClass){
                                        data.columnClass = "col-lg-3";
                                    }
                                }
                                else if(tempFieldsForm.header.type=='table' || tempFieldsForm.header.type=='serverTable'  || tempFieldsForm.header.type.includes('table') || tempFieldsForm.header.type.includes('Table')   || tempFieldsForm.header.type=='tableChecked' || tempFieldsForm.header.type=='addTable' || tempFieldsForm.header.type=='table_display' || (tempFieldsForm?.header?.attributes && JSON.parse(tempFieldsForm?.header?.attributes)?.tableType == 'SERVERTABLE')){
                                    if(tempFieldsForm.header.type=='addTable' ){
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:'slno',
                                            sortable: false,
                                            label: 'S No'
                                        },
                                        {
                                            key:'selected',
                                            sortable: false,
                                            label: ''
                                        })
                                    }
                                    else if(tempFieldsForm.header.type=='tableChecked'){
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:'slno',
                                            sortable: false,
                                            label: 'S No'
                                        },
                                        {
                                            key:'selected',
                                            sortable: false,
                                            label: ''
                                        })
                                    }
                                    else if (tempFieldsForm.header.type!='serverTable' && !(tempFieldsForm?.header?.attributes && JSON.parse(tempFieldsForm?.header?.attributes)?.tableType == 'SERVERTABLE')){
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:'slno',
                                            sortable: false,
                                            label: 'S No'
                                        })
                                    }
                                    if(tempFieldsForm.header.type=='serverTable' || (tempFieldsForm?.header?.attributes && JSON.parse(tempFieldsForm?.header?.attributes)?.tableType == 'SERVERTABLE')){
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:(list.fields && !list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(last((list.fields) ? list.fields.split(".") : list.fields)),
                                            sortable: true,
                                            name :    !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                            title : list.displayName,
                                            sortfields :!(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                            
                                        })
                                    }
                                    else if(data.table=='true' || typeof data.table  == 'undefined'){
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:(list.field && !list.field.includes(".")) ? stripHtml(list.field) : stripHtml(last((list.fields) ? list.fields.split(".") : list.fields)),
                                            sortable: true,
                                            name :    !(list && list.field && list.field.includes(".")) ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                                            title : list.displayName,
                                            grid_edit : data.girdEdit,
                                            disbabled: fieldStatusCheck,
                                            initDisbabled: fieldStatusCheck,
                                            api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                            editableTable: maindata.girdEditRightsRoles,
                                            fieldCode:  list.fieldCode,
                                            rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            label: list.displayName,
                                            model: !(list && list.field && list.field.includes(".")) ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                                            options : [],
                                            custom_validation: data,
                                            type:checkType(data.type),
                                            ...list
                                        })
                                    }
                                }
                                else if(data.columnClass==''){
                                    data.columnClass =  "col-lg-3";
                                }

                                let maxDays = list.attributes ? JSON.parse(list.attributes).maxDays : ''
                                let reviewDateMaxLimit = maxDays ? moment(Date.now()).add('days', maxDays).toISOString() : ''
                                
                                let fieldData = {
                                    sub_index : (sub_field_list.length>=1 && (sub_field_list.type=='fileupload'  || sub_field_list.type=='fileupload_full' || sub_field_list.type=='textarea_din' || sub_field_list.type=='select_file_upload')) ? 0 : 'false',
                                    sub_label : list.displayName,
                                    label: checkType(list.displayName),
                                    label_display: true,
                                    label_value: (data && data.label_value) ? data.label_value : '12',
                                    additional_rules : '',
                                    id:index,          
                                    grid_edit : data.GirdEdit,
                                    disbabled: fieldStatusCheck,
                                    initDisbabled: fieldStatusCheck,
                                    model: !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                    check_validations : ((data && data.check_validations) ? data.check_validations : false),
                                    check_field_validations : ((data && data.check_field_validations) ? data.check_field_validations : false),
                                    placeholder: (data && data.placeholder) ? data.placeholder : list.displayName,
                                    type: checkType(data.type),    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                    decimalLength : (data && data.decimalLength) ? data.decimalLength : '',
                                    columnClass: (data && data.columnClass) ? data.columnClass : "col-lg-4 col-md-6",
                                    rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                    initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                    sortInput: (data && data.ascorder && data.ascorder!=='false') ? true : false,
                                    fieldCode : list.fieldCode,
                                    additional_attributes: list.additionalAttributes,
                                    options:[],
                                    rows : 0.4,
                                    max_rows : 8,
                                    section_obj : maindata.obj_name,
                                    loader : ((data && data.type && (data.type.includes('select') || data.type.includes('_check'))) && !data.type.includes('dependent') && data.inter_dependent!='true') ? true : false,
                                    check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                    initCheckDisplay :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                    custom_validation: data,
                                    api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                    sub_fields: sub_field_list,
                                    name: stripHtml(list.displayName),
                                    min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' : data.isNextDate == 'true' ? moment().add(1, 'day').toISOString() : '',
                                    max_date: data.isFutureDate ? (data.isFutureDate=='false' ? moment(Date.now()).toISOString() : reviewDateMaxLimit ) :'',
                                    field_count : ( data && data.type && data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                    isMandatory:list.isMandatory,
                                    isFileRemove : (data && data.isFileRemove) ? data.isFileRemove : "true",
                                    addTable  : data.addField,
                                    authorities: list?.authorities,
                                    authorityCode: list?.authorityCode,
                                    isBtnDisable:false
                                }

                                fieldList.push(fieldData);
                                // moment().add(1, 'day').toISOString()
                                if((index+1==_temp_sections.length) && (investigation_page_index+1==tempGroupSection.length)){
                                    console.log('investigation_page_index', investigation_page_index)
                                }

                            })

                            if(tempFieldsForm.header.type=='multiple'){
                                let checkFields = [...fieldList]
                                tempFieldsForm.body = [];
                                tempFieldsForm.body.push(checkFields)
                                let baseForm = [];
                                if(tempFieldsForm.body && tempFieldsForm.body.length){
                                    tempFieldsForm.body.forEach((form_list)=>{
                                        form_list.forEach((list, index)=>{
                                            baseForm[index] = {};
                                            baseForm[index][list.model] = [];
                                            baseForm[index][list.model][0] = null;
                                        })
                                    })
                                    state.multi_section_pannel_form_data = baseForm
                                }

                            }
                            else if(tempFieldsForm.header.type=='addTable'){
                                tempFieldsForm.body = []
                                tempFieldsForm.body[0] = []
                                tempFieldsForm.body[0]=fieldList.filter((list)=>list.addTable==true)
                                let baseForm = [];
                                if(tempFieldsForm.body && tempFieldsForm.body.length){
                                    tempFieldsForm.body.forEach((form_list)=>{
                                        form_list.forEach((list, index)=>{
                                            baseForm[index] = {};
                                            baseForm[index][list.model] = [];
                                            if(list && list.custom_validation && list.custom_validation.default_value){
                                                baseForm[index][list.model][0] = list.custom_validation.default_value
                                            }
                                            else if(list.model=='date'){
                                                baseForm[index][list.model][0]= moment(new Date()).toISOString();
                                            }
                                            else if(list.model=='userName'){
                                                baseForm[index][list.model][0]= state.root_state.profile.userId
                                            }
                                            else if(list.model=='verticalCodeFrom'){
                                                baseForm[index][list.model][0]=  (state.root_state && state.root_state.profile.verticalDetails && state.root_state.profile.verticalDetails.code) ? state.root_state.profile.verticalDetails.code : '';
                                            }
                                            else{
                                                baseForm[index][list.model][0] = (list.model=='requestedBy') ? state.root_state.profile.userId : null;
                                            }
                                            
                                        })
                                    })
                                    state.table_add_form_data[tempFieldsForm.header.sectionObj] = baseForm
                                    state.table_add_form_data_init[tempFieldsForm.header.sectionObj] = JSON.parse(JSON.stringify(baseForm));
                                }
                            }
                            else{
                                tempFieldsForm.body = fieldList
                            }
                        }
                        _data.push(tempFieldsForm);
                    }
                }
            }))
            state.page_data_status = false
            console.log('stateName', stateName, _data)
            state[stateName] = {
                datas : _data,
                page_name : tempPageFields.description,
                subSectionCode : tempPageFields.subSectionCode,
                type:'multi_section_form',
            }
        }
    }
    else{
        state.page_data_status = false
        state.current_page_details = {}
        state[stateName] = {
            datas : [],
            page_name : '',
            multi_section: '',
            mult_datas : [],
            case_form_data
        };
    }
} 


const CalculateFromDatas = (formValue, fields, mainForm, pageName) => {
    let details = calculateFields.find((list) => list.pageName == pageName)
    console.log(formValue)
    if (details && details.fields) {
        details.fields.forEach((list) => {
            switch (list) {
                case 'insuredDetails__fraudCommittedBy':
                    if (mainForm[list]) {
                        let companyType = fields.find((list_sub) => list_sub.model == "insuredDetails__companyType")
                        console.log(mainForm,companyType,"mainForm")
                        if (mainForm[list] == 'Person') {
                            //mainForm['insuredDetails__companyType'] = ""
                            companyType.disbabled = true
                        } else {
                            companyType.disbabled = false
                        }
                    }
                    break
                
            }
        })
    }
    //console.log(formValue, fields, mainForm, pageName,"formValue, fields, mainForm, pageName")
}

const formateSingleObject  = (fields, field_values, _temp_object) =>{     
    for (let data in fields) {
        let _curr_obj = fields[data]
        let _split = fields[data].model.split("__");
        let _check_details= '';
        let a = `${_split[0]}`
        let b =  `${_split[1]}`
        _check_details = _.last(_split);    
        if(_split && _split.length>=2){
            if(field_values[a]){
                field_values[`${a}__${b}`] = field_values[a][b]
            }
        }
        if(field_values[_curr_obj.model]!='' && _check_details!=''){
            _temp_object[_curr_obj.model] = (_check_details  in field_values) ? field_values[_check_details] : ''
        }
        // to handle multi field
        if (Array.isArray(field_values[_curr_obj.model]) && fields[data].type == "multi_text_field_UM" ) {
            let count = field_values[_curr_obj.model].length;
            fields[data]['field_count'] = count
        }
        // to handle check boxes
        /* if (fields[data].type == "qccheckboxgroup") {
            field_values[_curr_obj.model].forEach((field) => {})
        } */
    }
    
}

export {
    CalculateFromDatas,
    formateSingleObject,
    disabledCheck,
    isJSON,
    processFrom
}