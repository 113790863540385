import Vue from "vue";
import S3 from 'aws-s3';
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import {EncodeString} from '@/shared/custom'
import {PostUiLogs} from '@/apis/Error'
const ApiUrl = process.env.VUE_APP_API_URL;
const ApiIlmUrl = process.env.VUE_APP_ILM_API_URL;
export default {
    FileUpload({ state, commit }, payload) {
        let _api_url = payload.url;
        let _token_details = state.auth;
        let _mutation_text = payload.mutation;
        let mh_media_change = payload.mh_media_change || ''
        let { requests } = payload
        console.log("payload-----------",requests)
        return new Promise((resolve, reject) => {
            if (requests) {
                commit('SHOW_LOADER', state)
                let headers = {
                    'Content-Type' : 'multipart/form-data'
                };
                if(_token_details && _token_details.token !== '') {
                    if(_token_details.token)
                    headers['auth'] = `${_token_details.type} ${_token_details.token}`
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                    if(_api_url.endsWith('uploadDocument')){
                        headers['x-api-key'] = process.env.VUE_APP_APIKEY
                    }
                }
                else{
                    headers['auth'] = ``
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                }
                let _check_media = _api_url.includes('Media')
                if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else if(mh_media_change && mh_media_change=="true"){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else{
                    Vue.http.options.root = ApiUrl
                }
                console.log("requests-----------",requests)
                Vue.http.post(_api_url, requests, {headers}).then(
                    (response) => {
                        Vue.nextTick(async() => {
                            let { status, responseData, message, statusCode = '' } = response.body
                            if(status=="SUCCESS"){
                                if(payload.module_name){
                                    commit(`${payload.module_name}/${_mutation_text}`, (message) ? {message:message, status:status, files:responseData} : {status:status, files:responseData})
                                }else{
                                    commit(_mutation_text, (message) ? {message:message, status:status, files:responseData , statusCode : statusCode ? statusCode : ''} : {status:status, files:responseData})
                                }
                                
                                if(payload.success_message){
                                    commit('RESPONSE_SUCCESS',{title : "Success", message:payload.success_message, varient :"success"})
                                }
                            }
                            else{
                                commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                if(payload.module_name){
                                    commit(`${payload.module_name}/${_mutation_text}`, (responseData) ? responseData : {status})
                                }else{
                                    commit(_mutation_text, (responseData) ? responseData : {status})
                                }
                            }
                            resolve(response)
                            state.loader = false
                        })
                    },
                    (error) => {
                        commit('RESPONSE_FAILURE',{title : "Network Failure", message:"Network Busy Try after some time", varient :"danger"})
                        reject(error)
                    }
                )
            }
        })
    },
    GetDownloadRequest({ dispatch, state, commit, rootState  }, payload) {
        let _api_url = payload.url;
        let _mutation_text = payload.mutation;
        let _encryption = payload.encryption;
        let mh_media_change = payload.mh_media_change || ''
        let _token_details = state.auth;
        let { requests } = payload
        let _temp_request = {...payload}
       
        requests = (requests && Object.keys(requests).length > 0  && _encryption!='false') ? EncodeString(requests).trim() : requests;
        return new Promise((resolve) => {
              
                commit('SHOW_LOADER', state)
                let headers = {}
                if (requests && Object.keys(requests).length === 0) {
                    headers = {
                        'Content-Type': 'application/json;charset=utf-8'
                    };
                }
                if(_token_details && _token_details.token !== '') {
                    if(_token_details.token)
                    headers['auth'] = `${_token_details.type} ${_token_details.token}`
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                }
                let _check_media = (_api_url) ? _api_url.includes('Media') : ''
                if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else if(mh_media_change && mh_media_change=="true"){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else{
                    Vue.http.options.root = ApiUrl
                }
                let _temp_req = {};
                if(_encryption!='false'){
                    _temp_req = {params : {payload:requests},headers : {...headers}}
                }
                else{
                    _temp_req = {params : requests,headers : {...headers}}
                }
                if(_api_url){
                    Vue.http.get(_api_url, _temp_req).then(
                        (response) => {
                            Vue.nextTick(async() => {
                                let { status, responseData, message, isResponseValid } = response.body
                                if(typeof status=='undefined'){
                                    responseData = response.body
                                    status = 'SUCCESS';
                                }
                                if(status=="SUCCESS" || status=="0" || message=="SUCCESS"){
                                    
                                    if(_temp_request.requests && _temp_request.requests.page_code){
                                        commit('REPORT_REQ_DETAILS', _temp_request.requests)
                                    }

                                    if(_temp_request.module_name){
                                        if(_temp_request.root_access && _temp_request.root_mutation_text&&_temp_request.root_access=="yes"){
                                            let mutate = _temp_request.root_mutation_text
                                            commit(`${_temp_request.module_name}/${mutate}`, rootState)
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                        }
                                        else if(_temp_request.send_payload){
                                            if(_mutation_text){
                                                commit(`${_temp_request.module_name}/${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                            else{
                                                commit(_mutation_text, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                        }
                                        else{
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                        }
                                    }
                                    else{
                                        if (_mutation_text == 'GET_LEGAL_SEARCH_REMARK_LIST') {
                                            commit(_mutation_text, {responseData , isResponseValid})
                                        }
                                        else if(_temp_request.send_payload){
                                            if(_mutation_text == 'GET_DOWNLOAD_DOCUMENT'){
                                                commit(`${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                            else if(_mutation_text){
                                                commit(`${_temp_request.module_name}/${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                            else{
                                                commit(_mutation_text, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                        }
                                        else{
                                            commit(_mutation_text, responseData)
                                        }
                                    }

                                }
                                else{
                                    commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                    dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:JSON.stringify(response)})
                                    state.loader = false
                                }
                                resolve(response)
                                state.loader = false
                            })
                        },
                        (error) => {
                            commit('RESPONSE_FAILURE',{title : `Network Failure: ${_api_url}`, message:"Network Busy Try after some time", varient :"danger"})
                            dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:error})
                            resolve(error)
                            state.loader = false
                        }
                    )
                }
            
        })
    },
    PostDownloadRequest({state, commit, dispatch}, payload) {        
        let _api_url = payload.url;
        let _token_details = state.auth;
        let _mutation_text = payload.mutation;
        let mh_media_change = payload.mh_media_change || ''
        let { requests } = payload
        let _temp_request = {...payload}
        return new Promise((resolve, reject) => {
            if (requests) {
                commit('SHOW_LOADER', state)
                let headers = {
                    'Content-Type': 'application/json;charset=utf-8'
                };
                if(_token_details && _token_details.token !== '') {
                    if(_token_details.token)
                    headers['auth'] = `${_token_details.type} ${_token_details.token}`
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                }
                
                let _check_media = _api_url.includes('Media')
               if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else if(mh_media_change && mh_media_change=="true"){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else{
                    Vue.http.options.root = ApiUrl
                }
                Vue.http.post(_api_url, JSON.stringify(requests),{headers, responseType: 'arraybuffer'}).then(
                    (response) => {
                        Vue.nextTick(async() => {

                            let {status, responseData,message , statusCode} = response.body
                            if(_temp_request.requests && _temp_request.requests.page_code){
                                commit('REPORT_REQ_DETAILS', _temp_request.requests)
                            }
                            if(status=="SUCCESS"){
                                if(_temp_request.module_name){
                                    commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                    commit(`${_temp_request.module_name}/${_mutation_text}`, (responseData) ? responseData : {status, message,statusCode})
                                }
                                else{      
                                    if(_mutation_text =="GET_DOWNLOAD_DOCUMENT_PDF"){
                                        commit(_mutation_text, (responseData) ? responseData :  '')
                                    }
                                    else{
                                        commit(_mutation_text, (responseData) ? {...responseData, message , statusCode} : {status, message:(message) ? message : '',statusCode : statusCode ? statusCode : ''})
                                    }
                                    
                                }
                                if(payload.success_message){
                                    commit('RESPONSE_SUCCESS',{title : "Success", message:payload.success_message, varient :"success"})
                                }
                            }
                            else if(response && response.status == '200'){
                                if(_mutation_text == 'GET_DOWNLOAD_DOCUMENT'){
                                    commit(`${_mutation_text}`, {responseData : response.body, apiRequest : _temp_request})
                                }
                            }
                            else{
                                if(_temp_request.module_name){  
                                    if(response.status && response.status=='200' && _mutation_text =="DOWNLOAD_TEMPLATE"){  
                                        let docName = _temp_request.requests.docName || "MasterTemplate.xlsx"
                                        commit(`${_temp_request.module_name}/${_mutation_text}`, {docData : response.body, docName: docName})
                                    }else{
                                        commit(`${_temp_request.module_name}/${_mutation_text}`, (responseData) ? responseData : {message, status})
                                    }  
                                }
                                else{
                                    commit(_mutation_text, (responseData) ? responseData : {message, status})
                                }

                                commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:JSON.stringify(responseData)})
                            }
                            resolve(response)
                            state.loader = false
                        })
                    },
                    (error) => {
                        commit('RESPONSE_FAILURE',{title :  `Network Failure: ${_api_url}`, message:"Network Busy Try after some time", varient :"danger"})
                        dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:error})
                        reject(error)
                    }
                )
            }
        })
    },
    PostRequest({state, commit, dispatch}, payload) {        
        let _api_url = payload.url;
        let _token_details = state.auth;
        let _mutation_text = payload.mutation;
        let mh_media_change = payload.mh_media_change || ''
        let { requests } = payload
        let _temp_request = {...payload}
        return new Promise((resolve, reject) => {
            if (requests) {
                commit('SHOW_LOADER', state)
                let headers = {
                    'Content-Type': 'application/json;charset=utf-8'
                };
                if(_token_details && _token_details.token !== '') {
                    if(_token_details.token)
                    headers['auth'] = `${_token_details.type} ${_token_details.token}`
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                }
                
                let _check_media = _api_url.includes('Media')
               if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else if(mh_media_change && mh_media_change=="true"){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else{
                    Vue.http.options.root = ApiUrl
                }
                if(_api_url){
                    let reqPayload;
                    if (requests instanceof FormData) {
                        // Payload is FormData, SHOULD NOT USE STRINGYFY FOR FORMDATA
                        reqPayload = requests
                    } else {
                        // Payload is not FormData
                        reqPayload = JSON.stringify(requests)
                    }
                    Vue.http.post(_api_url, reqPayload, {headers}).then(
                        (response) => {
                            Vue.nextTick(async() => {

                                let {status, responseData,message , statusCode} = response.body
                                if(_temp_request.requests && _temp_request.requests.page_code){
                                    commit('REPORT_REQ_DETAILS', _temp_request.requests)
                                }
                                if(status=="SUCCESS"){
                                    if(_temp_request.module_name){
                                        if(_mutation_text =="DOWNLOAD_BASE64_FILE" && response.status=='200'){
                                            let docName = _temp_request.requests.docName || ''
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, {docData : responseData , docName})
                                        }else if(_mutation_text =="PAYMENT_RETRY"){
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, response.body)
                                        }else{
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, (responseData) ? responseData : {status, message,statusCode})
                                        }
                                    }
                                    else{      
                                        if(_mutation_text =="GET_DOWNLOAD_DOCUMENT_PDF"){
                                            commit(_mutation_text, (responseData) ? responseData :  '')
                                        }
                                        else if(_mutation_text == 'GET_DOWNLOAD_DOCUMENT'){
                                            commit(_mutation_text, {responseData : responseData, apiRequest : _temp_request})
                                        }
                                        else{
                                            commit(_mutation_text, (responseData) ? {...responseData, message , statusCode} : {status, message:(message) ? message : '',statusCode : statusCode ? statusCode : ''})
                                        }
                                        
                                    }
                                    if(payload.success_message){
                                        commit('RESPONSE_SUCCESS',{title : "Success", message:payload.success_message, varient :"success"})
                                    }
                                }
                                else{
                                    if(_temp_request.module_name){ 
                                        if(response.status && response.status=='200' && (_mutation_text =="DOWNLOAD_TEMPLATE" || _mutation_text =="PAYMENT_RETRY")){  
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, response.body)
                                        }else{
                                            commit(`${_temp_request.module_name}/${_mutation_text}`, (responseData) ? responseData : {message, status})
                                        }  
                                    }
                                    else{
                                        commit(_mutation_text, (responseData) ? responseData : {message, status})
                                    }
                                    commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                    dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:JSON.stringify(responseData)})
                                }
                                resolve(response)
                                state.loader = false
                            })
                        },
                        (error) => {
                            commit('RESPONSE_FAILURE',{title :  `Network Failure: ${_api_url}`, message:"Network Busy Try after some time", varient :"danger"})
                            dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:error})
                            reject(error)
                        }
                    )
                }
            }
        })
    },
    GetIlmRequest({ dispatch, state, commit, rootState  }, payload) {
        let _api_url = payload.url;
        let _mutation_text = payload.mutation;
        let _encryption = payload.encryption;
        let mh_media_change = payload.mh_media_change || ''
        let _token_details = state.auth;
        let { requests } = payload
        let _temp_request = {...payload}
       
        requests = (requests && Object.keys(requests).length > 0  && _encryption!='false') ? EncodeString(requests) : requests;
        return new Promise((resolve) => {
              
                commit('SHOW_LOADER', state)
                let headers = {}
                if (requests && Object.keys(requests).length === 0) {
                    headers = {
                        'Content-Type': 'application/json;charset=utf-8'
                    };
                }
                if(_token_details && _token_details.token !== '') {
                    if(_token_details.token)
                    headers['auth'] = `${_token_details.type} ${_token_details.token}`
                    headers['corelationid'] =  uuidv4();
                    headers['businesscorelationid'] =  uuidv4();
                }
                let _check_media = (_api_url) ? _api_url.includes('Media') : ''
                if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else if(mh_media_change && mh_media_change=="true"){
                    Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                }
                else{
                    Vue.http.options.root = ApiIlmUrl
                }
                let _temp_req = {};
                if(_encryption!='false'){
                    _temp_req = {params : {payload:requests},headers : {...headers}}
                }
                else{
                    _temp_req = {params : requests,headers : {...headers}}
                }
                if(_api_url){
                  
                    Vue.http.get(_api_url, _temp_req).then(
                        (response) => {
                            Vue.nextTick(async() => {
                                let { status, responseData, message, isResponseValid } = response.body
                                if(typeof status=='undefined'){
                                    responseData = response.body
                                    status = 'SUCCESS';
                                }
                                console.log('status', status,responseData)
                                if(status=="SUCCESS" || message=="SUCCESS"){
                                    if(_temp_request.requests && _temp_request.requests.page_code){
                                        commit('REPORT_REQ_DETAILS', _temp_request.requests)
                                    }
                                    if(_temp_request.module_name){
                                        if(_temp_request.root_access&&_temp_request.root_mutation_text&&_temp_request.root_access=="yes"){
                                            let mutate = _temp_request.root_mutation_text
                                            commit(`${_temp_request.module_name}/${mutate}`, rootState)
                                        }
                                        commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                    }
                                    else{
                                        if (_mutation_text == 'GET_LEGAL_SEARCH_REMARK_LIST') {
                                            commit(_mutation_text, {responseData , isResponseValid})
                                        }else{
                                            commit(_mutation_text, responseData)
                                        }
                                    }

                                }
                                else{
                                    commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                    dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:JSON.stringify(response)})
                                    state.loader = false
                                }
                                resolve(response)
                                state.loader = false
                            })
                        },
                        (error) => {
                            commit('RESPONSE_FAILURE',{title : `Network Failure: ${_api_url}`, message:"Network Busy Try after some time", varient :"danger"})
                            dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:error})
                            resolve(error)
                            state.loader = false
                        }
                    )
                }
            
        })
    },
    GetRequest({ dispatch, state, commit, rootState  }, payload) {
        console.log("In action",payload)
        if(payload){
            let _api_url = payload.url;
            let _mutation_text = payload.mutation;
            let _encryption = payload.encryption;
            let mh_media_change = payload.mh_media_change || ''
            let _token_details = state.auth;
            let { requests } = payload
            let _temp_request = {...payload}
        
            requests = (requests && Object.keys(requests).length > 0  && _encryption!='false') ? EncodeString(requests) : requests;
            return new Promise((resolve) => {
                
                    commit('SHOW_LOADER', state)
                    let headers = {}
                    if (requests && Object.keys(requests).length === 0) {
                        headers = {
                            'Content-Type': 'application/json;charset=utf-8'
                        };
                    }
                    if(_token_details && _token_details.token !== '') {
                        if(_token_details.token)
                        headers['auth'] = `${_token_details.type} ${_token_details.token}`
                        headers['corelationid'] =  uuidv4();
                        headers['businesscorelationid'] =  uuidv4();
                    }
                    let _check_media = (_api_url) ? _api_url.includes('Media') : ''
                    if(_check_media && process.env.VUE_APP_MEDIA_CHNAGE=='true'){
                        Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                    }
                    else if(mh_media_change && mh_media_change=="true"){
                        Vue.http.options.root = process.env.VUE_APP_MEDIA_URL
                    }
                    else{
                        Vue.http.options.root = ApiUrl
                    }
                    let _temp_req = {};
                    if(_encryption!='false'){
                        _temp_req = {params : {payload:requests},headers : {...headers}}
                    }
                    else{
                        _temp_req = {params : requests,headers : {...headers}}
                    }
                    if(_api_url){
                        Vue.http.get(_api_url, _temp_req).then(
                            (response) => {
                                Vue.nextTick(async() => {
                                    let { status, responseData, message, isResponseValid } = response.body
                                    console.log("_api_url",_api_url,"response.body",response.body)
                                    if(typeof status=='undefined'){
                                        responseData = response.body
                                        status = 'SUCCESS';
                                    }
                                    if(status=="SUCCESS" || message=="SUCCESS"){
                                        if(_temp_request.requests && _temp_request.requests.page_code){
                                            commit('REPORT_REQ_DETAILS', _temp_request.requests)
                                        }
                                        console.log("_temp_request.module_name--------",_temp_request.module_name)
                                        if(_temp_request.module_name){
                                            console.log("IN IF")
                                            if(_temp_request.root_access&&_temp_request.root_mutation_text&&_temp_request.root_access=="yes"){
                                                let mutate = _temp_request.root_mutation_text
                                                commit(`${_temp_request.module_name}/${mutate}`, rootState)
                                            }
                                            else if(_temp_request.TableType == 'clientTable'){
                                                commit(`${_temp_request.module_name}/${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                            else{
                                               
                                                // if(_temp_request.mutation === "lOSS_ASSESSMENT_CAL"){
                                                //     commit("lOSS_ASSESSMENT_CAL", responseData)
                                                // }
                                                commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                            }
                                            // commit(`${_temp_request.module_name}/${_mutation_text}`, responseData)
                                        }
                                        else if(_temp_request.send_payload){
                                            console.log("IN ELSE IF")
                                            if(_temp_request.module_name){
                                                commit(`${_temp_request.module_name}/${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                            }
                                            else{
                                                console.log("_api_url-===",_api_url)
                                                if (_api_url && _api_url.includes('/fetchMicrCodeByIfsc') && response.body.getIFSCDetails!=null) {
                                                    console.log("response.body------",response.body,"responseData---",responseData)
                                                    responseData[0].bankName=response.body.getIFSCDetails[0].bankName;
                                                    responseData[0].branchName=response.body.getIFSCDetails[0].branchName
                                                    commit(`${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                                }else{
                                                commit(`${_mutation_text}`, {responseData : responseData, apiRequest : _temp_request})
                                                }
                                            }
                                        }
                                        else{
                                            console.log("IN ELSE",_mutation_text,response.body.responseData)
                                            if (_mutation_text == 'GET_LEGAL_SEARCH_REMARK_LIST') {
                                                commit(_mutation_text, {responseData , isResponseValid})
                                            }
                                            else{
                                                console.log("IN ELSE",_mutation_text,response.body.responseData)
                                                commit(_mutation_text, response.body.responseData)
                                            }
                                        }
                                        resolve(response)
                                    }
                                    else{
                                        commit('RESPONSE_FAILURE',{title : `${status}:`, message:(message) ? message : status, varient :"danger"})
                                        dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:JSON.stringify(response)})
                                        state.loader = false
                                    }
                                    resolve(response)
                                    state.loader = false
                                })
                            },
                            (error) => {
                                commit('RESPONSE_FAILURE',{title : `Network Failure: ${_api_url}`, message:"Network Busy Try after some time", varient :"danger"})
                                dispatch('UpdateUiLogs', {headers :headers, api_url:_api_url, req_init:JSON.stringify(requests), res_init:error})
                                resolve(error)
                                state.loader = false
                            }
                        )
                    }
                
            })
        }
    },
    async UpdateUiLogs({ state}, payload){
        let {headers, req_init, res_init, api_url} = payload
        let _temp_details = PostUiLogs()
        _temp_details.requests = {
            "correlation":(headers && headers.corelationid) ? headers.corelationid : 0,
            "businessCorrelation":(headers && headers.businesscorelationid) ? headers.businesscorelationid : 0,
            "apiName":api_url,
            "methodName":"GET",
            "action":"err",
            "request":req_init,
            "error":res_init,
            "errorMessage":res_init,
            "pageCode":'',
            "investigationId":"",
            "taskId":"",
            "userId":(state && state.profile) ? state.profile.email : {} ,
            "createdDate": new Date(),
            "type": "UI",
        }
        // dispatch('GetRequest', _temp_details)
    },
    async  SetAmazonUpload(payload){
        const config = {
            bucketName: 'fasbajajbucket',
            region: 'ap-south-1',
            accessKeyId: 'AKIA3SGBUOCH6LFN7TYM',
            secretAccessKey: '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',
            s3Url: 'https://fasbajajbucket.s3.ap-south-1.amazonaws.com/', /* optional */
        }
        const S3Client = new S3(config);
        let _promises = [];
        let _updated_details = [];

        _.forEach(payload, function(_file_list) {
            _promises.push(
                S3Client.uploadFile(_file_list.file, _file_list.filename)
                .then((data) => {
                    let name = data
                    return name
                })
                .then((response) => {
                   return response
                })
                .catch(err => err)
            );
        });

        await Promise.all(_promises).then(responses => {
            responses.map(response => {
                _updated_details.push(response)
                return response
            });
        });
    },
    SetLoginDetails({ commit }, payload) {
        commit('SET_LOGIN', payload)
    },
    RemoveLoginDetails({commit }){
        commit('REMOVE_LOGIN')
    },
    UPDATE_SIDE_MENU(state){
        state.display_menu = (state.display_menu==true) ? false  : true
    },
    resetAddClamiantForm({commit }) {
        console.log('In reset add claimant form action');
        commit(`nbs/RESET_ADD_CLAIMANT_FORM`);
    }
}