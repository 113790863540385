export default {
    menus() {
        return [{
            "id": "HOMELAYOUT",
            "path": "/",
            "name": "homelayout",
            "filePath": "container/pages/Layout",
            "redirect": "/dashboard",
            "auth": true,
            "children": [
                // {
                //     "id": "HOME",
                //     "path": "",
                //     "name": "home",
                //     "filePath": "container/pages/DashboardPage",
                //     "auth": true,
                //     "children": [],
                //     "roles": []
                // },
                {
                    "id": "DASHBOARD",
                    "path": "dashboard",
                    "name": "dashboard",
                    "filePath": "container/pages/DashboardPage",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "CLAIMINBOX",
                    "path": "claiminbox",
                    "name": "claiminbox",
                    "filePath": "container/pages/SingleFilter",
                    "auth": true,   
                    "children": [],
                    "roles": []
                },
                {
                    "id": "ALLOCATECLAIM",
                    "path": "allocateclaim",
                    "name": "allocateclaim",
                    "filePath": "container/pages/SingleFilter",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "NewClaimRegistration",
                    "path": '/new-claim-registration',
                    "name": "new-claim-registration",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "New_Claim_Registration",
                    "path": '/new-claim-registration-create',
                    "name": "New_Claim_Registration",
                    "filePath": "container/pages/NewClaimRegistration/NewClaimRegistrationLayout",
                    "auth" : true,
                    "children": [{
                            "id": "New_Claim_Registration_Details",
                            "path": '/new-claim-registration-create/:page_name',
                            "name": "New_Claim_Registration_Details",
                            "filePath": "container/pages/NewClaimRegistration/NewClaimRegistrationCreate",
                            "auth" : true,
                            "children": [],
                            "roles":[],
                            "meta": {
                                "breadcrumb": 'New_Claim_Registration_Details'
                            }
                        }
                    ],
                    "roles":[]
                },
                {
                    "id": "ADMIN",
                    "path": "admin",
                    "name": "admin",
                    "filePath": "container/pages/FieldDisplayData",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "ADMIN",
                    "path": "admin/:page_name",
                    "name": "adminPage",
                    "filePath": "container/pages/FieldDisplayData",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "REPORTS",
                    "path": "reports",
                    "name": "reports",
                    "filePath": "container/pages/SingleFilter",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "SUPPORT",
                    "path": "support",
                    "name": "support",
                    "filePath": "container/pages/SingleFilter",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "APROPAYMENT",
                    "path": "payment",
                    "name": "payment",
                    "filePath": "container/pages/SingleFilter",
                    "auth": true,
                    "children": [],
                    "roles": []
                },
                {
                    "id": "CLAIMINBOXDETAILS",
                    "path": "claim-inbox-details",
                    "name": "claiminboxdetails",
                    "filePath": "container/pages/FieldDisplayData",
                    "auth": true,
                    "children": [],
                    "roles": [],
                    "meta": {
                        "breadcrumb": 'Home'
                    }
                },
                {
                    "id": "CLAIMINBOXDETAILS",
                    "path": "allocate-claim-details",
                    "name": "allocateclaiminboxdetails",
                    "filePath": "container/pages/FieldDisplayData",
                    "auth": true,
                    "children": [],
                    "roles": [],
                    "meta": {
                        "breadcrumb": 'Home'
                    }
                },
                {
                    "id": "CLAIMDETAILS",
                    "path": "claim-process/:page_name",
                    "name": "claimdetails",
                    "filePath": "container/pages/FieldDisplayData",
                    "auth": true,
                    "children": [],
                    "roles": [],
                    "meta": {
                        "breadcrumb": 'Home'
                    }
                },
            ]
        },
        {
            "id": "PROFILE",
            "path": "/profile",
            "name": "profile",
            "filePath": "container/pages/Profile",
            "auth": true,
            "children": [],
            "roles": []
        },
        {
            "id": "ONLINEDOCUPLOAD",
            "path": "/claim-process/onlinedocuploadpage/:claimNumber",
            "name": "OnlineDocUpload",
            "filePath": "container/pages/OnlineDocUploadStatic",
            "auth": false,
            "roles": [],
            "meta": {
                "breadcrumb": 'Deterrence'
            }
        },
        {
            "id": "INSUREDcLAIMANTNEFT",
            "path": "/claim-process/insuredclaimantneft/:claimNumber/:partyId",
            "name": "OnlineDocUpload",
            "filePath": "container/pages/InsuredClaimantNeftStatic",
            "auth": false,
            "roles": [],
            "meta": {
                "breadcrumb": 'Deterrence'
            }
        },
        {
            "id": "FORGOTPASSWORD",
            "path": "/reset-link/forgot-password",
            "name": "ForgotPassword",
            "filePath": "container/pages/ForgotPassword",
            "auth": true,
            "roles": [],
            "meta": {
                "breadcrumb": 'Deterrence'
            }
        },
        {
            "id": "RESETPASSWORD",
            "path": "/reset-link/reset-password/:userid",
            "name": "ResetPassword",
            "filePath": "container/pages/ResetPassword",
            "auth": true,
            "roles": [],
            "meta": {
                "breadcrumb": 'Deterrence'
            }
        },
        {
            "id": "404",
            "path": "/:pathMatch(.*)*",
            "name": "404",
            "filePath": "container/pages/404",
            "auth": true,
            "children": [],
            "meta": {
                "breadcrumb": 'Home'
            }
        }
        ]
    }
}