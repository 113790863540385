import moment from 'moment'
const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];
  
  
function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
  
    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }
  
    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } at ${ hours }:${ minutes }`;
    }
  
    if (hideYear) {
      // 10. January at 10:20
      return `${ day }. ${ month } at ${ hours }:${ minutes }`;
    }
  
    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
}

export function convert_ddmmyy(date){
    let  _formate_Date = moment(new Date(date)).format("DD/MM/YYYY");
    return _formate_Date;
}
export function convert_ddmmyyhhmmssa(date){
  let  _formate_Date = moment(new Date(date)).format("DD/MM/YYYY h:mm:ss a");
  return _formate_Date;
}

export function convert_datetime24(date){
    let  _formate_Date = moment(new Date(date)).format('MM/DD/YYYY h:mm:ss a')
    return _formate_Date;
}


export function convert_msgdisplaydate(date){
  let  _formate_Date = moment(date).format('DD MMM h:mm a')
  return _formate_Date;
}

export function convert_toiso(date){
    let _formate_Date = moment(new Date(date)).toISOString();
    return _formate_Date;
}

export function convert_multi_date(date) {
    let _formate_Date = moment.utc(date).local().toISOString();
    return _formate_Date;
}

// export function convert_datetoiso(date){
//   console.log('date---------',date)
//   const parsedDate = moment(date, moment.ISO_8601, true);
//   if (parsedDate.isValid() || parsedDate.toISOString() === date) {
//     console.log("date1-------->",date);
//     return date
//   }

//   if(date){
//     date = date.toString();
//     date = date.split(' ')
//   }
//   else{
//     date = ''
//   }
//   if(date && date.length>1){
//     let _date = date[0].split('/').reverse()
//     let updatedDate = `${_date} ${date[1]}`
//     let _formate_Date = moment(new Date(updatedDate)).toISOString();
//     console.log("_formate_Date1-------->",_formate_Date);
//     return _formate_Date;
//   }
//   else if(date && date && date.length==1){
//     let _date = date[0].split('/').reverse()
//     let day = parseInt(_date[2])
//     _date[2] = day.toString()
//     let updatedDate = `${_date}`
//     let _formate_Date = moment(new Date(updatedDate)).toISOString();
//     console.log("_formate_Date2-------->",_formate_Date);
//     return _formate_Date;
//     }
//     else {
//         console.log("_formate_Date3-------->",_formate_Date);
//         return date
//     }
// }
export function convert_datetoiso(date) {
  console.log('Input date:', date);

  // Parse date using moment with strict ISO 8601 validation
  const parsedDate = moment(date, moment.ISO_8601, true);

  // If the date is already in ISO format or valid, return it as is
  if (parsedDate.isValid() && parsedDate.toISOString() === date) {
    console.log("Date is already in ISO format:", date);
    return date;
  }

  if (date) {
    // Convert date to string and split it into components
    date = date.toString().split(' ');
  } else {
    return '';
  }

  if (date.length > 1) {
    // Split the date part (before time) and reverse it for ISO format (YYYY-MM-DD)
    let _date = date[0].split('/').reverse().join('-');
    let time = date[1]; // Extract time part
    let period = date[2]; // Extract AM/PM

    // Combine the date, time, and AM/PM into a single string
    let updatedDate = `${_date} ${time} ${period}`;

    // Parse and format the date with time and AM/PM consideration
    let formattedDate = moment(updatedDate, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DDTHH:mm:ss');
    console.log("Formatted ISO date (with AM/PM):", formattedDate);
    return formattedDate;

  } else if (date.length === 1) {
    // Handle cases with just the date part
    let _date = date[0].split('/').reverse().join('-');
    let formattedDate = moment(new Date(_date)).format('YYYY-MM-DD');
    console.log("Formatted ISO date (without time):", formattedDate);
    return formattedDate;

  } else {
    // If the date format is not recognized, return as is
    console.log("Unrecognized date format, returning original:", date);
    return date;
  }
}


export function convert_difference(first_date, last_date,difference ='years'){
    let _first_date = moment(new Date(first_date)).format("DD-MM-YYYY HH:mm:ss");
    let _last_date = moment(new Date(last_date)).format("DD-MM-YYYY HH:mm:ss");
    var startDate = moment(_first_date, "DD-MM-YYYY HH:mm:ss");
    var endDate = moment(_last_date, "DD-MM-YYYY HH:mm:ss");
    if (difference == 'years') {
        return startDate.diff(endDate, difference);
    } else {
        return endDate.diff(startDate, difference);
    }
}


export function convert_yymmdd_display(date){
  let  _formate_Date = moment(new Date(date)).format("DD-MMM-yyyy");
  return _formate_Date;
}
  
export function timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }
  
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);

    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
  
    console.log('timeAgo',seconds, minutes, isToday)
    if (seconds < 5) {
      return 'now';
    } else if (seconds < 60) {
      return `${ seconds } seconds ago`;
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (minutes < 60) {
      return `${ minutes } minutes ago`;
    } else if (isToday) {
      return getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
      return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
      return getFormattedDate(date, false, true); // 10. January at 10:20
    }
  
    return getFormattedDate(date); // 10. January 2017. at 10:20
  }

/* export function convert_difference_time(first_date, last_date,difference ='years'){
    let _first_date = moment(new Date(first_date)).format("DD-MM-YYYY HH:mm:ss");
    let _last_date = moment(new Date(last_date)).format("DD-MM-YYYY HH:mm:ss");
    var startDate = moment(_first_date, "DD-MM-YYYY HH:mm:ss");
    var endDate = moment(_last_date, "DD-MM-YYYY HH:mm:ss");
    if (difference == 'seconds') {
        return startDate.diff(endDate, 'seconds');
    } else {
        return endDate.diff(startDate, 'seconds');
    }
} */