import { first, orderBy, last } from "lodash";
import { processFrom } from "@/shared/common";
import moment from "moment";
import { isDate } from "@/shared/custom";
import { convert_datetoiso, convert_yymmdd_display } from "@/shared/timer.js";

const MotorTp = {
  namespaced: true,
  state: {
    nb_case_investigator_id: {},
    surveyor_details_data: {},
    show_error: false,
    case_details: {
      loader: true,
      datas: [],
    },
    findTabData: {},
    fetch_service_ratingDetails: {},
    popup_page_datas: {},
    server_table_search: {},
    nb_case_card_data: [],
    investigation_by_id: {},
    isMobile: "",
    profile_nb_state_city: {},
    active_report_menu: {},
    form_page_list: [],
    page_fields: {
      datas: [],
      page_name: "",
      display_type: "text",
    },
    multi_section_page_fields: {
      datas: [],
      page_name: "",
      subSectionCode: "",
      type: "",
    },
    multi_section_header_fields: {
      datas: [],
      page_name: "",
      subSectionCode: "",
      type: "",
    },
    openPopup: false,
    openPopupCode: "",
    active_sub_menu: {},
    root_state: {},
    multi_text_data: [],
    selected_data: {},
    client_tables: {},
    server_selected_data: {},
    save_data: {},
    display_triggers: false,
    page_data_status: false,
    multi_section_form_data_copy: {},
    multi_section_form_data: {},
    multi_section_header_data: {},
    current_menu: {},
    current_main_menu: {},
    look_by_type_lists: [],
    lookupby_type: [],
    header_data: {},
    multi_section_form_baseData: {},
    tableFormData: {},
    doclist_data: [],
    uploadTableFile: {},
    lossAssesmentCalFields: {},
    neftDetails: {},
    neftDocuments: [],
    showOther: true,
    disableData: true,
    disableRaise: false,
    addRowinDamage: 0,
    ckycdata: {},
    searchPartnerFlag: false,
    requestForPaymentPageObj: {
      isGSTPayable: false,
      isForeignCurrency: false,
    },
    selectedRowIndex: null,
    tableFormArr: [],
    tableFieldsData: [],
    buttonGroupCode: {},
    tableFormDeleteData: {},
    checkBox: null,
    gstPayableArr: [
      {
        billNo: "",
        billDate: "",
        grossBilledAmount: "",
        gstBaseAmount: "",
        raiseOnBagic: "",
        allowedGstAmt: "",
        remarks: "",
        file: "",
        // selectedFile: "",
        // gstIn: "",
        // validateFlag: true,
      },
    ],
    previous_tab:{},
    selectedPaymentRows:[],
    poiDocList:[],
    poaDocList:[],
  },
  mutations: {
    // lOSS_ASSESSMENT_CAL (state, payload) {
    //     state.lossAssesmentCalFields = payload
    // },
    RATING_DETAILS(state, payload) {
      if (payload && payload.serviceRatingDetails) {
        state.fetch_service_ratingDetails = payload.serviceRatingDetails;
      }
    },
    CLEAR_PAGE_DATA(state) {
      state.multi_section_page_fields = {
        datas: [],
        page_name: "",
        subSectionCode: "",
        type: "",
      };
      state.nb_investigation_page_fields = [];
      state.page_data_status = true;
    },
    UPDATE_SELECTED_PAYMENT(state, payload) {
      state.selectedPaymentRows.push(payload);
    },
    CREATE_TABLE_STATE(state, { sectionObj }) {
      if (!state.client_tables[sectionObj]) {
        state.client_tables[sectionObj] = [];
      }
    },
    GET_HEADER_DATA(state, payload) {
      state.header_data = payload;
    },
    UPDATE_SELECT_DATA(state, payload) {
      state.selected_data = { ...payload };
    },
    UPDATE_ACTIVE_REPORT_MENU(state, payload) {
      state.active_report_menu = payload;
    },
    UPDATE_CLIENT_TABLE_REQ(
      state,
      { headerObj, dataIndex, dataFieldMain, changeType, selectType }
    ) {

      if (changeType == "single") {
        let mainDataCheck =
          state.client_tables[headerObj][dataIndex][dataFieldMain];
        state.client_tables[headerObj][dataIndex][dataFieldMain] = mainDataCheck
          ? false
          : true;
      } else if (changeType == "removeaRecord") {
        let mainDataCheck = state.client_tables[headerObj].filter(
          (list, index) => list && index != dataIndex
        );
        state.client_tables[headerObj] = mainDataCheck;
        state.client_tables = { ...state.client_tables };
      } else if (changeType == "pushTiggers") {
        let mainDataCheck = state.client_tables[headerObj];
        mainDataCheck.push(state.manual_triggers);
        state.manual_triggers = {};
        state.client_tables[headerObj] = [...mainDataCheck];
        state.client_tables = { ...state.client_tables };
        state.display_triggers = false;
      } else if (changeType == "selectAll") {
        let mainDataCheck = state.client_tables[headerObj];
        if (mainDataCheck) {
          let checkDataMain = mainDataCheck.map((list) => {
            return { ...list, ...{ required: selectType } };
          });
          state.client_tables[headerObj] = [...checkDataMain];
          state.client_tables = { ...state.client_tables };
        }
      }
    },
    MOVE_NEXT_TAB_DATAS(state, payload) {
      state.findTabData = payload;
    },
    NB_CRTEATE_TABLE_DATA(state, { apiRequest, responseData }) {
      if (apiRequest && apiRequest.headerObj == "evidenceList") {
        state.client_tables[apiRequest.headerObj] =
          responseData[0].evidenceList.map((list) => {
            return { ...list, ...{ required: false } };
          });
      } else if (apiRequest && apiRequest.headerObj == "assessment") {
        state.client_tables[apiRequest.headerObj] =
          responseData.assesmentValueDetails;
      } else if (
        apiRequest &&
        apiRequest.headerObj == "evaluateSupplierPerformance"
      ) {
        state.client_tables[apiRequest.headerObj] = responseData.map((list) => {
          return { ...list, ...{ required: true } };
        });
      } else if (apiRequest && apiRequest.headerObj && responseData) {
        state.client_tables[apiRequest.headerObj] = responseData;
      }
    },
    SAVE_DATA_GRADING(state, playload) {
      state.save_data = playload;
    },
    GET_CLAIM_DOC_DATAS(state, payload) {
      if (payload && payload.length) {
        const mappedArr = payload.map((doc_list) => {
          const attribute = doc_list.attributes.startsWith("{")
            ? JSON.parse(doc_list.attributes)
            : {};
          return { ...doc_list, ...attribute };
        });
        state.doclist_data = mappedArr ? mappedArr : [];
      }
    },
    UPDATED_SERVER_TABLE_SELECTED_DATA(state, { selectedData, componentObj }) {
      if (!state.server_selected_data[componentObj]) {
        state.server_selected_data[componentObj] = [];
      }
      state.server_selected_data[componentObj] = selectedData;
    },

    GET_LOOKUP_VALUES(state, payload) {
      let { responseData, apiRequest } = payload;
      if (
        responseData &&
        responseData.lookupTypeValues &&
        responseData.lookupTypeValues.length
      ) {
        let lookupValues = orderBy(responseData.lookupTypeValues, ['orderNo'], ['asc'])
        let values = lookupValues.map((list) => {
          return {
            text: list.value,
            value: list.value,
            code: list.value,
          };
        });
        if (
          apiRequest.requests.fieldName &&
          apiRequest.requests.fieldType == "select"
        ) {
          state.lookupby_type = [...values];
        } else if (apiRequest.requests.fieldType == "select") {
          state.lookupby_type = values;
        } else {
          state.lookupby_type = values;
        }
      } else if (
        payload &&
        payload.lookupTypeValues &&
        payload.lookupTypeValues.length
      ) {
        let lookupValues = orderBy(payload.lookupTypeValues, ['orderNo'], ['asc'])
        let values = lookupValues.map((list) => {
          return {
            text: list.value,
            value: list.value,
            code: list.value,
          };
        });
        state.lookupby_type = values;
      } else {
        state.lookupby_type = [];
      }
    },

    GET_MASTER_LOOKUP(state, payload) {
      let { responseData, apiRequest } = payload;
      if (responseData && responseData.length) {
        let fieldsDetails = orderBy(responseData, ["orderNo"], ["asc"]);
        let values = [];
        if (
          apiRequest &&
          apiRequest.requests &&
          apiRequest.requests.code == "INVESTIGATION_OFFICER_NAME"
        ) {
          values = fieldsDetails.map((list) => {
            return {
              text: list.value
                ? list.value.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.email,
              value: list.userId
                ? list.userId.trim()
                : list.userId
                  ? list.userId.trim()
                  : list.userId,
              code: list.code
                ? list.code.trim()
                : list.code
                  ? list.code.trim()
                  : list.code,
            };
          });
        } else if (apiRequest && apiRequest.url.includes("/fetchCaseStatus")) {
          let tempList = fieldsDetails.map((list) => {
            return {
              text: list.value
                ? list.value.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
              value: list.code
                ? list.code.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
              code: list.code
                ? list.code.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
            };
          });
          values = [
            { text: "-- Select a Status --", value: "", code: "" },
            ...tempList,
          ];
        } else {
          values = fieldsDetails.map((list) => {
            return {
              text: list.value
                ? list.value.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
              value: list.code
                ? list.code.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
              code: list.code
                ? list.code.trim()
                : list.firstName
                  ? list.firstName.trim()
                  : list.name,
            };
          });
        }

        if (apiRequest && apiRequest.url.includes("/fetchCaseStatus")) {
          state.case_status = fieldsDetails;
        }

        if (apiRequest.requests.fieldType == "select") {
          state.lookupby_type = values;
        } else {
          state.lookupby_type = values;
        }
      } else if (payload && payload.length) {
        state.lookupby_type = payload.map((list) => {
          return {
            text: list.value
              ? list.value.trim()
              : list.firstName
                ? list.firstName.trim()
                : list.name,
            value: list.code
              ? list.code.trim()
              : list.firstName
                ? list.firstName.trim()
                : list.name,
            code: list.code
              ? list.code.trim()
              : list.firstName
                ? list.firstName.trim()
                : list.name,
          };
        });
      } else {
        state.lookupby_type = [];
      }
    },
    EDIT_SECTION_DETAILS(state, payload) {
      let tempObj = {};
      if (typeof payload == "object") {
        for (let formObj in payload) {
          if (typeof payload[formObj] == "object") {
            for (let subObj in payload[formObj]) {
              tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj];
            }
            tempObj = { ...tempObj, ...payload[formObj] };
          } else {
            if (isDate(payload[formObj])) {
              tempObj[formObj] = convert_datetoiso(payload[formObj]);
            } else tempObj[`${formObj}`] = payload[formObj];
          }
        }
      }
      state.multi_section_form_baseData = { ...tempObj };
    },

    POPUP_PAGE_DATAS(state, payload) {
      state.popup_page_datas = payload;
    },

    GET_PAGE_CD_DETAILS(state, mainpayload) {
      let tempObj = {};
      state.multi_section_saved_form_data = mainpayload;
      if (mainpayload) {
        Object.keys(state.multi_section_saved_form_data).map((ele) => {
          var pdate = Date.parse(state.multi_section_saved_form_data[ele]);
          if (state.multi_section_saved_form_data[ele] != null || state.multi_section_saved_form_data[ele] != '') {
            if (
              ele == 'appointmentDate' ||
              ele == 'initialVisitDate' ||
              ele == 'interimReportDate' ||
              ele == 'finalReportDate' ||
              ele == 'invoiceDate' ||
              ele == 'lorryReceiptDate' ||
              ele == 'scInvoiceNo'
            )
              state.multi_section_saved_form_data[ele] = convert_datetoiso(state.multi_section_saved_form_data[ele]);
          }
        })
      }
      let { current_menu, multi_section_page_fields } = state;
      // console.log('cuv00', mainpayload)
      if (mainpayload?.phiPartnerId) {
        state.phiPartnerId = mainpayload?.phiPartnerId;
      }
      state.claimProcessId = (mainpayload?.claimProcessId) ? mainpayload?.claimProcessId : null;
      // if (mainpayload?.currentStepId) {
      //   state.currentStepId = mainpayload?.currentStepId;
      // }
      state.currentStepId = (mainpayload?.currentStepId) ? mainpayload?.currentStepId : null;
      state.header_data = { ...state.header_data, ...mainpayload };

      if (window.location.href.includes("evaluatesupplierperformance")) {
        state.rating_page_fields = mainpayload;
      }
      if (mainpayload && mainpayload.serviceRatingDetails) {
        state.fetch_service_ratingDetails = mainpayload.serviceRatingDetails;
      }
      let payload =
        mainpayload &&
          mainpayload[current_menu.pageObject] &&
          typeof mainpayload[current_menu.pageObject] !== "undefined"
          ? mainpayload[current_menu.pageObject]
          : mainpayload;
      state.tabel_form_data = {};

      if (payload) {
        for (let formObj in payload) {

          if (Array.isArray(payload[formObj])) {
            let temPsection = state.multi_section_page_fields.datas.find(
              (list) => list.header.sectionObj == formObj
            );
            if (temPsection) {
              if (
                temPsection &&
                temPsection.header.type &&
                temPsection &&
                (temPsection.header.type == "table" ||
                  temPsection.header.type == "tableChecked" ||
                  temPsection.header.type == "addTable" ||
                  temPsection.header.type == "table_display")
              ) {
                state.tabel_form_data[temPsection.header.sectionObj] = [];
                if (payload[formObj] && payload[formObj].length) {
                  let sectionFeilds =
                    temPsection.header &&
                      temPsection.header.tabelHeader.length > 0
                      ? temPsection.header.tabelHeader
                      : [];
                  state.tabel_form_data[temPsection.header.sectionObj] =
                    payload[formObj].map((list_form, list_index) => {
                      let pageDatas = list_form;
                      for (let pageData in pageDatas) {
                        let checkFieldType = sectionFeilds.find(
                          (list) => list.formPageFields == pageData
                        );
                        if (checkFieldType) {
                          let getAttributes =
                            checkFieldType.attributes &&
                              checkFieldType.attributes.startsWith("{")
                              ? JSON.parse(checkFieldType.attributes)
                              : {};
                          if (
                            checkFieldType &&
                            getAttributes &&
                            getAttributes.type &&
                            (getAttributes.type.includes("date") ||
                              getAttributes.type.includes("Date"))
                          ) {
                            if (getAttributes.girdEdit == true) {
                              pageDatas[pageData] = pageDatas[pageData]
                                ? convert_datetoiso(pageDatas[pageData])
                                : "";
                            } else {
                              pageDatas[pageData] = pageDatas[pageData]
                                ? convert_yymmdd_display(
                                  convert_datetoiso(pageDatas[pageData])
                                )
                                : "";
                            }
                          }
                        }

                        if (pageData == "isSelect") {
                          let getData =
                            state.selected_data[temPsection.header.sectionObj];
                          if (getData && getData.includes(list_index)) {
                            pageDatas[pageData] = true;
                          }
                        }
                      }
                      return pageDatas;
                    });
                }
              } else if (
                temPsection &&
                temPsection.header.type &&
                temPsection &&
                temPsection.header.type.includes("multiple")
              ) {
                let sectionFields = state.multi_section_page_fields.datas;
                if (sectionFields) {
                  let tempField = sectionFields.find(
                    (list) => list.header.type == "multiple"
                  );
                  let tempIndex = findIndex(
                    sectionFields,
                    (list) => list.header.type == "multiple"
                  );
                  let tempFieldSet = [];
                  let firstSet = {};
                  let finalArray = [];
                  if (tempIndex && tempField) {
                    if (payload[formObj] && payload[formObj].length) {
                      firstSet = first(
                        state.multi_section_page_fields.datas[tempIndex].body
                      );
                      for (
                        let incForm = 0;
                        incForm < payload[formObj].length;
                        incForm++
                      ) {
                        tempFieldSet.push(firstSet);
                      }
                    }

                    if (tempFieldSet && tempFieldSet.length) {
                      let temValueArray = [];
                      payload[formObj].forEach((list) => {
                        let incVal = 0;
                        for (let fieldSet in firstSet) {
                          let findTarget =
                            list[last(firstSet[fieldSet].model.split("__"))];
                          if (typeof temValueArray[incVal] == "undefined") {
                            temValueArray[incVal] = {};
                          }
                          if (
                            typeof temValueArray[incVal][
                            firstSet[fieldSet].model
                            ] == "undefined"
                          ) {
                            temValueArray[incVal][firstSet[fieldSet].model] =
                              [];
                          }
                          temValueArray[incVal][firstSet[fieldSet].model].push(
                            findTarget
                          );
                          incVal++;
                        }
                      });

                      finalArray = temValueArray;
                      state.multi_section_pannel_form_data = finalArray;
                      state.multi_section_page_fields.datas[tempIndex].body =
                        tempFieldSet;
                    }
                  }
                }
              }
            }
          } else if (typeof payload[formObj] == "object") {
            for (let subObj in payload[formObj]) {
              tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj];
            }
            tempObj = { ...tempObj, ...payload[formObj] };
          }
          else {
            // console.log('current_menu', current_menu, payload)
            tempObj[`${formObj}`] = payload[formObj];

          }
        }
      }
      tempObj = { ...payload, ...tempObj }
      if (typeof payload == "object") {
        for (let formObj in payload) {
          if (isDate(payload[formObj])) {
            state.multi_section_form_data[formObj] = moment
              .utc(payload[formObj])
              .format();
          }
        }
      }
      let _temp_dates = {}
      if (payload && payload.claimReviewDetails) {
        for (let formObj in payload.claimReviewDetails) {
          // if (isDate(payload.claimReviewDetails[formObj])) {
          //   _temp_dates[`claimReviewDetails__${formObj}`] = moment.utc(payload[formObj]).format();
          // }
          if (isDate(payload.claimReviewDetails[formObj])) {
            _temp_dates[`claimReviewDetails__${formObj}`] = convert_datetoiso(payload.claimReviewDetails[formObj]);
          }

        }
        state.multi_section_form_data = { ...payload.claimReviewDetails };
      } else if (payload && payload.claimDetails) {
        for (let formObj in payload.claimDetails) {
          if (isDate(payload.claimDetails[formObj])) {
            _temp_dates[`claimDetails__${formObj}`] = convert_datetoiso(payload.claimDetails[formObj]);
          }

        }
        state.multi_section_form_data = { ...payload.claimDetails };
      } else if (payload && payload.id != null) {
        for (let formObj in payload) {
          if (isDate(payload[formObj])) {
            _temp_dates[formObj] = convert_datetoiso(payload[formObj]);

          }
        }
        state.multi_section_form_data = { ...payload };
      }
      //Data overriding is occurring in both tempObj and state.multi_section_form_data as they share the same key.
      state.multi_section_form_data = {
        ...state.multi_section_form_data,
        ...tempObj,
        ..._temp_dates
      };
      state.multi_section_form_data_copy = tempObj;
      // state.multi_section_form_data = tempObj
      // console.log('tempObj', state.multi_section_form_data)
    },

    async GET_HEADER_PAGE_FIELDS(state, payload) {
      console.log("GET_HEADER_PAGE_FIELDS",payload)
      if (payload && payload.length > 0) {
        processFrom(state, payload, "multi_section_header_fields");
      }
    },

    GET_MULTI_SECTION_PAGE_LIST(state, payload) {
      let tempData =
        payload && payload.length ? payload[0].formPageSection : [];
      let orderedData = orderBy(tempData, ["orderNo"], ["asc"]);
      const mappedArr = orderedData.map((doc_list) => {
        const attribute =
          doc_list.attributes && doc_list.attributes.startsWith("{")
            ? JSON.parse(doc_list.attributes)
            : {};
        return { ...doc_list, ...attribute };
      });
      state.form_page_list = mappedArr;
      // state.form_page_list = orderedData;
      if (orderedData && orderedData.length >= 1) {
        //new changes for tab
        let tabIndex = state.form_page_list?.findIndex((form_page)=>{
          return form_page.sectionCode===state.previous_tab?.sectionCode
        })
        // console.log('state.previous_tab',JSON.parse(JSON.stringify(state.previous_tab)))
        if(JSON.parse(state.current_menu.attributes)?.pageType==='tab' && tabIndex>0 && state.previous_tab){

          state.active_sub_menu = state.previous_tab;
        }else{
          state.active_sub_menu = orderedData[0];
        }
        //new changes for tab end
        // state.active_sub_menu = orderedData[0];
      }
    },

    UPDATE_ACTIVE_SUB_MENU(state, payload) {
      state.active_sub_menu = payload;
    },

    UPDATE_ALLOCATE_CLAIM(state, payload) {
      if (payload && payload.surveyDetails) {
        state.surveyor_details_data = payload.surveyDetails;
      }
    },

    async GET_MULTI_SECTION_PAGE_FIELDS(state, payload) {
      let case_form_data = [];
      state.validate_count_sections = [];
      console.log("GET_MULTI_SECTION_PAGE_FIELDS---nbs",payload)
      if (payload && payload.length > 0) {
        processFrom(state, payload, "multi_section_page_fields");
      } else {
        state.page_data_status = false;
        state.current_page_details = {};

        state.multi_section_page_fields = {
          datas: [],
          page_name: "",
          multi_section: "",
          mult_datas: [],
          case_form_data,
        };
      }
    },

    CURRENT_PAGE(state, playload) {
      state.current_menu = playload;
    },

    CURRENT_MAIN_PAGE(state, playload) {
      state.current_main_menu = playload;
    },


    UPDATE_SEARCHCLAIM_DETAILS(state, payload) {
      let searchclaimDetails = { ...state.searchclaim_details };
      searchclaimDetails[payload.state_name] = payload.value;
      state.searchclaim_details = searchclaimDetails;
    },
    GET_NEFT_DETAILS(state, payload) {
      // console.log('In GET_NEFT_DETAILS mutation ', payload);
      state.neftDetails = payload.neftDetails;
    },
    GET_MULTI_ROW_TABLE_DATA(state, payload) {
      // console.log('In GET_NEFT_DETAILS mutation ', payload);
      state.multiRowFields = payload;
    },
    MULTI_ROW_TABLE_ALL_DATA(state, payload) {
      // console.log('In GET_NEFT_DETAILS mutation ', payload);
      state.multiRowFields = payload;
    },
    GET_NEFT_DOCUMENT_DETAILS(state, payload) {
      // console.log('In GET_NEFT_DOCUMENT_DETAILS mutation ', payload);
      state.neftDocuments = payload;
    },
    RESET_ADD_CLAIMANT_FORM(state, payload) {
      // console.log('In reset claimanr form mutation');
      state.popup_page_datas = {};
    },
    REMOVE_CLAIMANT(state, payload) {
      console.log("In remove clamiant mutation");
    },
    SAVE_SEARCH_PARTY_DATA(state, payload) {
      let data = payload?.partyRespObjDto;
      if (data) {
        let newObj = {};
        // newObj.sr__ = data?.sr;
        // newObj.name = data?.firstName;
        // newObj.phone = data?.mobileNumber;
        // newObj.dob = data?.dob;
        newObj.proposerName = data?.proposerName;
        newObj.firstName = data?.firstName;
        newObj.middleName = data?.middleName;
        newObj.lastName = data?.lastName;
        newObj.gender = data?.gender;
        newObj.address = data?.address;
        newObj.dateOfBirth = data?.dateOfbirth;
        newObj.streetName = data?.streetName;
        newObj.subAreaCity = "";
        newObj.pinCode = data?.pinCode;
        newObj.country = data?.country;
        newObj.telephoneNumber = data?.telephoneNumber;
        newObj.maritalStatus = data?.maritalStatus;
        newObj.employeeStatus = data?.employeeStatus;
        newObj.email = data?.emailId;
        newObj.fax = data?.faxNumber;
        newObj.mobileNumber = data?.mobileNumber;
        newObj.partyCode = data?.partyCode;
        newObj.subType = data?.subType;
        newObj.institutionName = "";
        newObj.houseOrbuilding = "";
        newObj.beforeTitle = "";
        newObj.recordSeleted = data?.recordSeleted;
        newObj.partnerAs = data?.partnerAs;
        newObj.sr = data?.sr;
        newObj.customerName = data?.customerName;
        state.popup_page_datas = {
          ...state.popup_page_datas,
          ...newObj,
        };
        state.searchPartnerFlag = true;
      }
    },
    ADD_CLAIMANT(state, payload) {
      console.log("in add claimant mutations ", payload);
    },
    FETCH_CURRENCY_RATE(state, payload) {
      console.log("in fetch currency mutation ");
    },
    UPDATE_PREVIOUS_TAB(state,payload){
      state.previous_tab=payload;
    }
  },
};

export default MotorTp;
