exports.vendorPayment = { 
    fieldGroupCode: 'professionalFees',
}

exports.reportMenus_constants = {
    report_pageCode : {
        pastHospital : 'past_hospital_visit',
    },
    report_sectionCode : {
        discharge_questionnaire : 'discharged_questionnaire',
        patient_visit_questionnaire : 'patient_visit_questionnaire'
    }
}

exports.Report_Pages_constants = {
    vendor_grading : 'Vendor Grading',
    trigger_questionnaire : 'Trigger Questionnaire',
    evidence_grid : 'Evidence Grid'
}

exports.graphDataLabelOptions = {
    enabled: true,
    style: {
        colors: ["#0068b4"],
        background: {
            foreColor: "#0068b4d4"
        }
    },
    formatter: function (value,{seriesIndex}) {
        if(seriesIndex===1){
          return value + "%";
        }
        else{
            return value;
        }
    }
}