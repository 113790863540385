// import Vue from "vue";
import {first, omit, orderBy, sortBy,last, findIndex, isEmpty} from "lodash";
import moment from 'moment'
import {convert_datetoiso, convert_yymmdd_display} from '@/shared/timer.js'
import {} from "../../shared/common";
import {stripHtml, checkType, createRule, FieldStatusCheck} from "@/shared/custom";

const VendorPayment = {
    namespaced: true,
    state: {
        page_fields:{
            datas : [],
            page_name : '',
            display_type : 'text'
        },
        multi_section_page_fields:{
            datas : [],
            page_name : '',
            subSectionCode : '',
            type:'',
        },
        pop_multi_section_page_fields:{
            datas : [],
            page_name : '',
            subSectionCode : '',
            type:'',
        },
        root_state : {},
        multi_text_data :[],
        searchclaim_details : {},
        current_menu : '',
        table_add_form_data : {},
        lookupby_type : [],
        table_add_form_data_init :{},
        multi_section_form_data : {},
        multi_section_saved_form_data : [],
        tabel_form_data : {},
        look_by_type_lists : [],
        multi_section_pannel_form_data : [],
        multi_section_popup_form_data: [],
        customeButton: false,
    },
    mutations: {
        is_mobile(state, payload){
            state.isMobile = payload
        },
        GET_MD_ROOTSTATE_DETAILS(state, payload){
            state.root_state = payload;
        },
        UPDATE_ACTIVE_SECTION(state, payload){
            state.current_menu = (payload) ? payload : ''
        },
        UPDATE_TABLE_ADD_FROM(state, payload){
            state.table_add_form_data = {...payload.formData}
        },
        UPDATE_TABLE_ADD_DATA(state, payload){
            state.tabel_form_data = {...payload.formData}
            if(payload && payload.formAction && payload.formAction=='add'){
                let getAllData = state.table_add_form_data
                getAllData[payload.formSectionObj] = state.table_add_form_data_init[payload.formSectionObj];
                state.table_add_form_data = {...getAllData}
            }
        },

        async GET_MULTI_SECTION_PAGE_FIELDS(state, payload){
            let case_form_data = []
            state.validate_count_sections = []
            if(payload && payload.length>0){
                let tempPageFields = first(payload)
                let _data = [];  
                let sub_field_list = [];    
                if(tempPageFields && tempPageFields.formPageFieldGroups && tempPageFields.formPageFieldGroups.length){
                    let tempGroupSection = tempPageFields.formPageFieldGroups;
                    tempGroupSection =  sortBy(tempGroupSection, function (obj) {
                        return parseInt((obj.orderNo) ? obj.orderNo : 0);
                    });     
                    tempGroupSection = orderBy(tempGroupSection, ['orderNo'],['asc'])   
                    await Promise.all(tempGroupSection.map(async (investigation_page_fields, investigation_page_index)=>{
                        let maindata = (investigation_page_fields.attributes && investigation_page_fields.attributes.startsWith("{") && investigation_page_fields.attributes) ? JSON.parse(investigation_page_fields.attributes) : {      
                            "type": "single"
                        }  
                        let tempFieldsForm = {
                            header :{},
                            body:{},
                            tabelHeader: [],
                        }
                        let fieldList = [];
                        tempFieldsForm.header = omit(investigation_page_fields, ['fields']);
                        tempFieldsForm.header['type'] = maindata.type
                        tempFieldsForm.header['validate'] = true
                        tempFieldsForm.header['roles'] = []
                        tempFieldsForm.header['validateCount'] = []
                        tempFieldsForm.header['addRightsRole'] = []
                        tempFieldsForm.header['girdEditRightsRoles'] = []
                        tempFieldsForm.header['visibleRightsRole'] = []
                        tempFieldsForm.header['visibleButton'] = []
                        tempFieldsForm.header['custome_button'] = false;
                        tempFieldsForm.header['default_button'] = false;
                        tempFieldsForm.header['custome_button_values'] = [];
                        tempFieldsForm.header['is_workflow'] = false;
                        tempFieldsForm.header['sectionObj'] = ''
                        tempFieldsForm.header['tabelHeader'] = []
                        if(maindata && maindata.roleCode){
                            tempFieldsForm.header['roles'] = maindata.roleCode;
                        }
                        if(maindata && maindata.addRightsRole){
                            tempFieldsForm.header['addRightsRole'] = maindata.addRightsRole;
                        }
                        if(maindata && maindata.visibleRightsRole){
                            tempFieldsForm.header['visibleRightsRole'] = maindata.visibleRightsRole;
                        }
                        if(maindata && maindata.visibleButton){
                            tempFieldsForm.header['visibleButton'] = maindata.visibleButton;
                        }
                        if(maindata && maindata.girdEditRightsRoles){
                            tempFieldsForm.header['girdEditRightsRoles'] = maindata.girdEditRightsRoles;
                        }
                        if(maindata && maindata.obj_name){
                            tempFieldsForm.header['sectionObj'] = maindata.obj_name;
                        }
                        if(maindata && maindata.custome_button){
                            tempFieldsForm.header['custome_button'] = maindata.custome_button;
                        }
                        if(maindata && maindata.custome_button_values){
                            tempFieldsForm.header['custome_buttons'] = maindata.custome_button_values;
                        }
                        if(maindata && maindata.is_workflow==true){
                            tempFieldsForm.header['is_workflow'] = true;
                        }

                        if(maindata && maindata.custome_button_action_id){
                            tempFieldsForm.header['custome_button_action_id'] = maindata.custome_button_action_id;
                        }

                        if(maindata && maindata.validate_count){
                            state.validate_count_sections.push(tempFieldsForm.header.sectionObj)
                        }

                        
                        if(maindata && maindata.default_button){
                            tempFieldsForm.header['default_button'] = maindata.default_button;
                        }
                        tempFieldsForm.header.model = (tempFieldsForm.header.displayName)
                        if(investigation_page_fields){
                            let _temp_data = {...investigation_page_fields};
                            if (typeof _temp_data !== 'undefined' && _temp_data.formPageFields && _temp_data.formPageFields.length){
                                state.current_page_details = omit(_temp_data,  ['formPageFieldGroups'])
                                let _temp_sections = _temp_data.formPageFields; 
                                _temp_sections =  sortBy(_temp_sections, function (obj) {
                                    return parseInt((obj.orderNo) ? obj.orderNo : 0);
                                }); 
                                if(_temp_sections && _temp_sections.length>=1){
                                    _temp_sections.forEach((list, index)=>{
                                        let data = (list && list.attributes && (list.attributes)) ? JSON.parse(list.attributes) : {
                                            "label_value":"6",        
                                            "type": "text",
                                            "columnClass": "",
                                        }  

                                        if(tempPageFields.authorities == null || tempPageFields.authorities == undefined){
                                            tempPageFields.authorities = {"authorityCode": "READ_WRITE"}
                                        }
                                        if(_temp_data.authorityCode == null || _temp_data.authorityCode == undefined){
                                            _temp_data.authorityCode = "READ_WRITE"
                                        }
                                        
                                        let fieldStatusCheck = FieldStatusCheck(tempGroupSection, list.authorities, {}, list.authorityCode);  
                                        state.customeButton = fieldStatusCheck
                                        if(data && !data.addField){
                                            data.addField = false;
                                        } 
                                        else {
                                            data.addField = true;
                                        }

                                        if(data && !data.girdEdit){
                                            data.girdEdit = false;
                                        } 
                                            
                                        if(tempFieldsForm.header.type=='multiple'){
                                            if(!data.columnClass){
                                                data.columnClass = "col-lg-3";
                                            }
                                        }
                                        else if(tempFieldsForm.header.type=='table' || tempFieldsForm.header.type=='tableChecked' || tempFieldsForm.header.type=='addTable' || tempFieldsForm.header.type=='table_display'){
                                            if(tempFieldsForm.header.type=='addTable' ){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                },
                                                {
                                                    key:'selected',
                                                    sortable: false,
                                                    label: ''
                                                })
                                            }
                                            else if(tempFieldsForm.header.type=='tableChecked'|| tempFieldsForm.header.type=='riskCoverCheckTable'){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                },
                                                {
                                                    key:'selected',
                                                    sortable: false,
                                                    label: ''
                                                })
                                            }
                                            else{
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                })
                                            }
                                            

                                            if(data.table=='true' || typeof data.table  == 'undefined'){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:(list.field && !list.fields.includes(".")) ? stripHtml(list.field) : stripHtml(last(list.fields.split("."))),
                                                    sortable: true,
                                                    grid_edit : data.girdEdit,
                                                    disbabled: fieldStatusCheck,
                                                    initDisbabled: fieldStatusCheck,
                                                    api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                                    editableTable: maindata.girdEditRightsRoles,
                                                    fieldCode:  list.fieldCode,
                                                    rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                    initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                    label: list.displayName,
                                                    model: !(list && list.field && list.fields.includes(".")) ? stripHtml(list.field) : stripHtml(list.fields.replaceAll(".", "__")),
                                                    options : [],
                                                    custom_validation: data,
                                                    type:checkType(data.type),
                                                    ...list
                                                })
                                            }
                                        }
                                        else if(data.columnClass==''){
                                            data.columnClass =  "col-lg-3";
                                        }
                                        
                                        
                                        let fieldData = {
                                            sub_index : (sub_field_list.length>=1 && (sub_field_list.type=='fileupload'  || sub_field_list.type=='fileupload_full' || sub_field_list.type=='textarea_din' || sub_field_list.type=='select_file_upload')) ? 0 : 'false',
                                            sub_label : list.displayName,
                                            label: checkType(list.displayName),
                                            label_display: true,
                                            label_value: (data && data.label_value) ? data.label_value : '12',
                                            additional_rules : '',
                                            id:index,          
                                            grid_edit : data.GirdEdit,
                                            disbabled: fieldStatusCheck,
                                            initDisbabled: fieldStatusCheck,
                                            model: !(list && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                            check_validations : ((data && data.check_validations) ? data.check_validations : false),
                                            check_field_validations : ((data && data.check_field_validations) ? data.check_field_validations : false),
                                            placeholder: (data && data.placeholder) ? data.placeholder : list.displayName,
                                            type: checkType(data.type),    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                            decimalLength : (data && data.decimalLength) ? data.decimalLength : '',
                                            columnClass: (data && data.columnClass) ? data.columnClass : "col-lg-4 col-md-6",
                                            rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            sortInput: (data && data.ascorder && data.ascorder!=='false') ? true : false,
                                            fieldCode : list.fieldCode,
                                            additional_attributes: list.additionalAttributes,
                                            options:[],
                                            rows : 0.4,
                                            max_rows : 8,
                                            section_obj : maindata.obj_name,
                                            loader : ((data && data.type && (data.type.includes('select') || data.type.includes('_check'))) && !data.type.includes('dependent') && data.inter_dependent!='true') ? true : false,
                                            check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                            initCheckDisplay :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                            custom_validation: data,
                                            api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                            sub_fields: sub_field_list,
                                            name: stripHtml(list.displayName),
                                            min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' :'' ,
                                            max_date: data.isFutureDate ? data.isFutureDate=='false' ? moment(Date.now()).toISOString():'' :'' ,
                                            field_count : ( data && data.type && data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                            isMandatory:list.isMandatory,
                                            isFileRemove : (data && data.isFileRemove) ? data.isFileRemove : "true",
                                            addTable  : data.addField
                                        }

                                        fieldList.push(fieldData);
                                    })

                                    if(tempFieldsForm.header.type=='multiple'){
                                        let checkFields = [...fieldList]
                                        tempFieldsForm.body = [];
                                        tempFieldsForm.body.push(checkFields)
                                        let baseForm = [];
                                        if(tempFieldsForm.body && tempFieldsForm.body.length){
                                            tempFieldsForm.body.forEach((form_list)=>{
                                                form_list.forEach((list, index)=>{
                                                    baseForm[index] = {};
                                                    baseForm[index][list.model] = [];
                                                    baseForm[index][list.model][0] = null;
                                                })
                                            })
                                            state.multi_section_pannel_form_data = baseForm
                                        }

                                    }
                                    else if(tempFieldsForm.header.type=='addTable'){
                                        tempFieldsForm.body = []
                                        tempFieldsForm.body[0] = []
                                        tempFieldsForm.body[0]=fieldList.filter((list)=>list.addTable==true)
                                        let baseForm = [];
                                        if(tempFieldsForm.body && tempFieldsForm.body.length){
                                            tempFieldsForm.body.forEach((form_list)=>{
                                                form_list.forEach((list, index)=>{
                                                    baseForm[index] = {};
                                                    baseForm[index][list.model] = [];
                                                    if(list && list.custom_validation && list.custom_validation.default_value){
                                                        baseForm[index][list.model][0] = list.custom_validation.default_value
                                                    }
                                                    else if(list.model=='date'){
                                                        baseForm[index][list.model][0]= moment(new Date()).toISOString();
                                                    }
                                                    else if(list.model=='userName'){
                                                        baseForm[index][list.model][0]= state.root_state.profile.userId
                                                    }
                                                    else if(list.model=='verticalCodeFrom'){
                                                        baseForm[index][list.model][0]=  (state.root_state && state.root_state.profile.verticalDetails && state.root_state.profile.verticalDetails.code) ? state.root_state.profile.verticalDetails.code : '';
                                                    }
                                                    else{
                                                        baseForm[index][list.model][0] = (list.model=='requestedBy') ? state.root_state.profile.userId : null;
                                                    }
                                                    
                                                })
                                            })
                                            state.table_add_form_data[tempFieldsForm.header.sectionObj] = baseForm
                                            state.table_add_form_data_init[tempFieldsForm.header.sectionObj] = JSON.parse(JSON.stringify(baseForm));
                                        }
                                    }
                                    else{
                                        tempFieldsForm.body = fieldList
                                    }
                                }
                                _data.push(tempFieldsForm);
                            }
                        }
                    }))

                    state.multi_section_page_fields = {
                        datas : _data,
                        page_name : tempPageFields.description,
                        subSectionCode : tempPageFields.subSectionCode,
                        type:'multi_section_form',
                    }
                }
            }
            else{
                state.current_page_details = {}
                state.multi_section_page_fields = {
                    datas : [],
                    page_name : '',
                    multi_section: '',
                    mult_datas : [],
                    case_form_data
                };
            }
        },

        async GET_POP_MULTI_SECTION_PAGE_FIELDS(state, payload){
            let case_form_data = []
            state.validate_count_sections = []
            if(payload && payload.length>0){
                let tempPageFields = first(payload)
                let _data = [];  
                let sub_field_list = [];    
                if(tempPageFields && tempPageFields.formPageFieldGroups && tempPageFields.formPageFieldGroups.length){
                    let tempGroupSection = tempPageFields.formPageFieldGroups;
                    tempGroupSection =  sortBy(tempGroupSection, function (obj) {
                        return parseInt((obj.orderNo) ? obj.orderNo : 0);
                    });     
                    tempGroupSection = orderBy(tempGroupSection, ['orderNo'],['asc'])   
                    await Promise.all(tempGroupSection.map(async (investigation_page_fields, investigation_page_index)=>{
                        let maindata = (investigation_page_fields.attributes && investigation_page_fields.attributes.startsWith("{") && investigation_page_fields.attributes) ? JSON.parse(investigation_page_fields.attributes) : {      
                            "type": "single"
                        }  
                        let tempFieldsForm = {
                            header :{},
                            body:{},
                            tabelHeader: [],
                        }
                        let fieldList = [];
                        tempFieldsForm.header = omit(investigation_page_fields, ['fields']);
                        tempFieldsForm.header['type'] = maindata.type
                        tempFieldsForm.header['validate'] = true
                        tempFieldsForm.header['roles'] = []
                        tempFieldsForm.header['validateCount'] = []
                        tempFieldsForm.header['addRightsRole'] = []
                        tempFieldsForm.header['girdEditRightsRoles'] = []
                        tempFieldsForm.header['visibleRightsRole'] = []
                        tempFieldsForm.header['visibleButton'] = []
                        tempFieldsForm.header['custome_button'] = false;
                        tempFieldsForm.header['default_button'] = false;
                        tempFieldsForm.header['custome_button_values'] = [];
                        tempFieldsForm.header['is_workflow'] = false;
                        tempFieldsForm.header['sectionObj'] = ''
                        tempFieldsForm.header['tabelHeader'] = []
                        if(maindata && maindata.roleCode){
                            tempFieldsForm.header['roles'] = maindata.roleCode;
                        }
                        if(maindata && maindata.addRightsRole){
                            tempFieldsForm.header['addRightsRole'] = maindata.addRightsRole;
                        }
                        if(maindata && maindata.visibleRightsRole){
                            tempFieldsForm.header['visibleRightsRole'] = maindata.visibleRightsRole;
                        }
                        if(maindata && maindata.visibleButton){
                            tempFieldsForm.header['visibleButton'] = maindata.visibleButton;
                        }
                        if(maindata && maindata.girdEditRightsRoles){
                            tempFieldsForm.header['girdEditRightsRoles'] = maindata.girdEditRightsRoles;
                        }
                        if(maindata && maindata.obj_name){
                            tempFieldsForm.header['sectionObj'] = maindata.obj_name;
                        }
                        if(maindata && maindata.custome_button){
                            tempFieldsForm.header['custome_button'] = maindata.custome_button;
                        }
                        if(maindata && maindata.custome_button_values){
                            tempFieldsForm.header['custome_buttons'] = maindata.custome_button_values;
                        }
                        if(maindata && maindata.is_workflow==true){
                            tempFieldsForm.header['is_workflow'] = true;
                        }

                        if(maindata && maindata.custome_button_action_id){
                            tempFieldsForm.header['custome_button_action_id'] = maindata.custome_button_action_id;
                        }

                        if(maindata && maindata.validate_count){
                            state.validate_count_sections.push(tempFieldsForm.header.sectionObj)
                        }

                        
                        if(maindata && maindata.default_button){
                            tempFieldsForm.header['default_button'] = maindata.default_button;
                        }
                        tempFieldsForm.header.model = (tempFieldsForm.header.displayName)
                        if(investigation_page_fields){
                            let _temp_data = {...investigation_page_fields};
                            if (typeof _temp_data !== 'undefined' && _temp_data.formPageFields && _temp_data.formPageFields.length){
                                state.current_page_details = omit(_temp_data,  ['formPageFieldGroups'])
                                let _temp_sections = _temp_data.formPageFields; 
                                _temp_sections =  sortBy(_temp_sections, function (obj) {
                                    return parseInt((obj.orderNo) ? obj.orderNo : 0);
                                }); 
                                if(_temp_sections && _temp_sections.length>=1){
                                    _temp_sections.forEach((list, index)=>{
                                        let data = (list && list.attributes && (list.attributes)) ? JSON.parse(list.attributes) : {
                                            "label_value":"6",        
                                            "type": "text",
                                            "columnClass": "",
                                        }  

                                        if(tempPageFields.authorities == null || tempPageFields.authorities == undefined){
                                            tempPageFields.authorities = {"authorityCode": "READ_WRITE"}
                                        }
                                        if(_temp_data.authorityCode == null || _temp_data.authorityCode == undefined){
                                            _temp_data.authorityCode = "READ_WRITE"
                                        }
                                        
                                        let fieldStatusCheck = FieldStatusCheck(tempGroupSection, list.authorities, {}, list.authorityCode);  
                                        state.customeButton = fieldStatusCheck
                                        if(data && !data.addField){
                                            data.addField = false;
                                        } 
                                        else {
                                            data.addField = true;
                                        }

                                        if(data && !data.girdEdit){
                                            data.girdEdit = false;
                                        } 
                                            
                                        if(tempFieldsForm.header.type=='multiple'){
                                            if(!data.columnClass){
                                                data.columnClass = "col-lg-3";
                                            }
                                        }
                                        else if(tempFieldsForm.header.type=='table' || tempFieldsForm.header.type=='tableChecked' || tempFieldsForm.header.type=='addTable' || tempFieldsForm.header.type=='table_display'){
                                            if(tempFieldsForm.header.type=='addTable' ){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                },
                                                {
                                                    key:'selected',
                                                    sortable: false,
                                                    label: ''
                                                })
                                            }
                                            else if(tempFieldsForm.header.type=='tableChecked'){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                },
                                                {
                                                    key:'selected',
                                                    sortable: false,
                                                    label: ''
                                                })
                                            }
                                            else{
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:'slno',
                                                    sortable: false,
                                                    label: 'S No'
                                                })
                                            }
                                            

                                            if(data.table=='true' || typeof data.table  == 'undefined'){
                                                tempFieldsForm.header.tabelHeader.push({
                                                    key:(list.field && !list.fields.includes(".")) ? stripHtml(list.field) : stripHtml(last(list.fields.split("."))),
                                                    sortable: true,
                                                    grid_edit : data.girdEdit,
                                                    disbabled: fieldStatusCheck,
                                                    initDisbabled: fieldStatusCheck,
                                                    api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                                    editableTable: maindata.girdEditRightsRoles,
                                                    fieldCode:  list.fieldCode,
                                                    rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                    initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                    label: list.displayName,
                                                    model: !(list && list.field && list.fields.includes(".")) ? stripHtml(list.field) : stripHtml(list.fields.replaceAll(".", "__")),
                                                    options : [],
                                                    custom_validation: data,
                                                    type:checkType(data.type),
                                                    ...list
                                                })
                                            }
                                        }
                                        else if(data.columnClass==''){
                                            data.columnClass =  "col-lg-3";
                                        }
                                        
                                        
                                        let fieldData = {
                                            sub_index : (sub_field_list.length>=1 && (sub_field_list.type=='fileupload'  || sub_field_list.type=='fileupload_full' || sub_field_list.type=='textarea_din' || sub_field_list.type=='select_file_upload')) ? 0 : 'false',
                                            sub_label : list.displayName,
                                            label: checkType(list.displayName),
                                            label_display: true,
                                            label_value: (data && data.label_value) ? data.label_value : '12',
                                            additional_rules : '',
                                            id:index,          
                                            grid_edit : data.GirdEdit,
                                            disbabled: fieldStatusCheck,
                                            initDisbabled: fieldStatusCheck,
                                            model: !(list && list.formPageFields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                            check_validations : ((data && data.check_validations) ? data.check_validations : false),
                                            check_field_validations : ((data && data.check_field_validations) ? data.check_field_validations : false),
                                            placeholder: (data && data.placeholder) ? data.placeholder : list.displayName,
                                            type: checkType(data.type),    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                            decimalLength : (data && data.decimalLength) ? data.decimalLength : '',
                                            columnClass: (data && data.columnClass) ? data.columnClass : "col-lg-4 col-md-6",
                                            rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            sortInput: (data && data.ascorder && data.ascorder!=='false') ? true : false,
                                            fieldCode : list.fieldCode,
                                            additional_attributes: list.additionalAttributes,
                                            options:[],
                                            rows : 0.4,
                                            max_rows : 8,
                                            section_obj : maindata.obj_name,
                                            loader : ((data && data.type && (data.type.includes('select') || data.type.includes('_check'))) && !data.type.includes('dependent') && data.inter_dependent!='true') ? true : false,
                                            check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                            initCheckDisplay :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                            custom_validation: data,
                                            api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                            sub_fields: sub_field_list,
                                            name: stripHtml(list.displayName),
                                            min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' :'' ,
                                            max_date: data.isFutureDate ? data.isFutureDate=='false' ? moment(Date.now()).toISOString():'' :'' ,
                                            field_count : ( data && data.type && data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                            isMandatory:list.isMandatory,
                                            isFileRemove : (data && data.isFileRemove) ? data.isFileRemove : "true",
                                            addTable  : data.addField
                                        }
                                        fieldList.push(fieldData);
                                    })

                                    if(tempFieldsForm.header.type=='multiple'){
                                        let checkFields = [...fieldList]
                                        tempFieldsForm.body = [];
                                        tempFieldsForm.body.push(checkFields)
                                        let baseForm = [];
                                        if(tempFieldsForm.body && tempFieldsForm.body.length){
                                            tempFieldsForm.body.forEach((form_list)=>{
                                                form_list.forEach((list, index)=>{
                                                    baseForm[index] = {};
                                                    baseForm[index][list.model] = [];
                                                    baseForm[index][list.model][0] = null;
                                                })
                                            })
                                            state.multi_section_pannel_form_data = baseForm
                                        }

                                    }
                                    else if(tempFieldsForm.header.type=='addTable'){
                                        tempFieldsForm.body = []
                                        tempFieldsForm.body[0] = []
                                        tempFieldsForm.body[0]=fieldList.filter((list)=>list.addTable==true)
                                        let baseForm = [];
                                        if(tempFieldsForm.body && tempFieldsForm.body.length){
                                            tempFieldsForm.body.forEach((form_list)=>{
                                                form_list.forEach((list, index)=>{
                                                    baseForm[index] = {};
                                                    baseForm[index][list.model] = [];
                                                    if(list && list.custom_validation && list.custom_validation.default_value){
                                                        baseForm[index][list.model][0] = list.custom_validation.default_value
                                                    }
                                                    else if(list.model=='date'){
                                                        baseForm[index][list.model][0]= moment(new Date()).toISOString();
                                                    }
                                                    else if(list.model=='userName'){
                                                        baseForm[index][list.model][0]= state.root_state.profile.userId
                                                    }
                                                    else if(list.model=='verticalCodeFrom'){
                                                        baseForm[index][list.model][0]=  (state.root_state && state.root_state.profile.verticalDetails && state.root_state.profile.verticalDetails.code) ? state.root_state.profile.verticalDetails.code : '';
                                                    }
                                                    else{
                                                        baseForm[index][list.model][0] = (list.model=='requestedBy') ? state.root_state.profile.userId : null;
                                                    }
                                                    
                                                })
                                            })
                                            state.table_add_form_data[tempFieldsForm.header.sectionObj] = baseForm
                                            state.table_add_form_data_init[tempFieldsForm.header.sectionObj] = JSON.parse(JSON.stringify(baseForm));
                                        }
                                    }
                                    else{
                                        tempFieldsForm.body = fieldList
                                    }
                                }
                                _data.push(tempFieldsForm);
                            }
                        }
                    }))

                    state.pop_multi_section_page_fields = {
                        datas : _data,
                        page_name : tempPageFields.description,
                        subSectionCode : tempPageFields.subSectionCode,
                        type:'multi_section_form',
                    }
                }
            }
            else{
                state.current_page_details = {}
                state.pop_multi_section_page_fields = {
                    datas : [],
                    page_name : '',
                    multi_section: '',
                    mult_datas : [],
                    case_form_data
                };
            }
        },

        UPDATE_MULTISET_SEARCHCLAIM_DETAILS(state, payload){
            if(payload.type=='sidebar'){
                let values = state.multi_sidebar_data
                values.forEach((value_list)=>{
                    for(let value in value_list){
                        if(value==payload.state_name){
                           
                            value_list[value][payload.form_index] = payload.value
                        }
                    }
                })
                state.multi_sidebar_data = [...values]
            
            }
            else{
                let values = state.multi_text_data
                if(values){
                    values.forEach((value_list)=>{
                        for(let value in value_list){
                            if(value==payload.state_name){
                                value_list[value][payload.form_index] = payload.value
                            }
                        }
                    })
                }
                state.multi_text_data = [...values]
            }
        },
        GET_PAGE_CD_DETAILS(state, mainpayload){
            this.$store.state.nbs.fetch_service_ratingDetails = {};
            let tempObj = {};
            let {current_menu} = state;
            let payload = (mainpayload && mainpayload[current_menu.pageObject] && typeof mainpayload[current_menu.pageObject] !== 'undefined') ? mainpayload[current_menu.pageObject] : mainpayload
            state.tabel_form_data = {};
            if(payload){
                for(let formObj in payload){
                    if(Array.isArray(payload[formObj])){
                        let temPsection = state.multi_section_page_fields.datas.find(list=>list.header.sectionObj==formObj);
                        if(temPsection){
                            if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='table'  || temPsection.header.type=='tableChecked' || temPsection.header.type=='addTable' || temPsection.header.type=='table_display')){
                                state.tabel_form_data[temPsection.header.sectionObj] = []
                                if((payload[formObj] && payload[formObj].length)){
                                    let sectionFeilds = (temPsection.header && temPsection.header.tabelHeader.length>0) ? temPsection.header.tabelHeader : [];
                                    state.tabel_form_data[temPsection.header.sectionObj] = payload[formObj].map((list_form)=>{
                                        let pageDatas = list_form
                                        for(let pageData in pageDatas){
                                            let checkFieldType = sectionFeilds.find((list)=>list.fields==pageData);
                                            if(checkFieldType){
                                                let getAttributes = (checkFieldType.attributes && checkFieldType.attributes.startsWith("{")) ? JSON.parse(checkFieldType.attributes) : {}
                                                if(checkFieldType &&  (getAttributes && getAttributes.type && (getAttributes.type.includes('date') || getAttributes.type.includes('Date')))){
                                                    if((getAttributes.girdEdit==true)){
                                                        pageDatas[pageData] =  (pageDatas[pageData]) ? convert_datetoiso(pageDatas[pageData]) : ''
                                                    }
                                                    else{
                                                        pageDatas[pageData] =  (pageDatas[pageData]) ? convert_yymmdd_display(convert_datetoiso(pageDatas[pageData])) : ''
                                                    }
                                                }
                                            }
                                        }
                                        return pageDatas;
                                    })
                                }
                            }
                            else  if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type.includes('multiple'))){
                                let sectionFields = state.multi_section_page_fields.datas
                                if(sectionFields){
                                    let tempField = sectionFields.find(list=>list.header.type=="multiple")
                                    let tempIndex =  findIndex(sectionFields, (list)=>list.header.type=="multiple");
                                    let tempFieldSet = []
                                    let firstSet  = {};
                                    let finalArray = [];
                                    if(tempIndex && tempField){
                                        if(payload[formObj] && payload[formObj].length){
                                            firstSet = first(state.multi_section_page_fields.datas[tempIndex].body)
                                            for(let incForm = 0; incForm<payload[formObj].length; incForm++){
                                                tempFieldSet.push(firstSet)
                                            }
                                        }
        
                                        if(tempFieldSet && tempFieldSet.length){ 
                                            let temValueArray = [];
                                            payload[formObj].forEach((list)=>{
                                                let incVal = 0;
                                                for(let fieldSet in firstSet){
                                                    let findTarget = list[last(firstSet[fieldSet].model.split('__'))]
                                                    if(typeof temValueArray[incVal] == 'undefined'){
                                                        temValueArray[incVal] = {}
                                                    }
                                                    if(typeof temValueArray[incVal][firstSet[fieldSet].model] == 'undefined'){
                                                        temValueArray[incVal][firstSet[fieldSet].model] = []
                                                    }
                                                    temValueArray[incVal][firstSet[fieldSet].model].push(findTarget)
                                                    incVal++;
                                                }
                                            })
        
                                            finalArray = temValueArray
                                            state.multi_section_pannel_form_data = finalArray
                                            state.multi_section_page_fields.datas[tempIndex].body = tempFieldSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else if(typeof payload[formObj]=='object'){
                        for(let subObj in payload[formObj]){
                            tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj]
                        }
                    }
                    else{
                        tempObj[`${formObj}`] = payload[formObj]
                    }
                }
                
            }
            state.multi_section_form_data = tempObj
        },
    
        UPDATE_PANEL_DETAILS(state, payload){
            let {datas} = state.multi_section_page_fields;
            if(datas && datas.length >= payload.form_index){
                let registrationDetails = state.multi_section_pannel_form_data
                if(payload.type==true){
                    registrationDetails = state.multi_section_popup_form_data
                }
                if(payload.action=="deduction"){
                    registrationDetails.forEach((list)=>{
                        for(let details in list){
                            list[details] = list[details].filter((list, index)=>index != payload.main_index)
                        }
                    })
                }
                else if(payload.action=="add"){
                    registrationDetails.forEach((list)=>{
                        for(let details in list){
                            if(details=='requestedBy'){
                                list[details].push(state.root_state.profile.userId)
                            }
                            else if(details=='date'){
                                list[details].push(moment(new Date()).toISOString());
                            }
                            else{
                                list[details].push(null)
                            }
                        }
                    })
                }
            }
        },
    
        UPDATE_TABLE_DATA(state, payload){
            let tempData = JSON.parse(JSON.stringify(state.tabel_form_data));
            if(tempData && tempData[payload.sectionName]){
                tempData[payload.sectionName].push(payload.sectionData);
                state.table_add_form_data = {};
                state.tabel_form_data = {}
                state.tabel_form_data = {...tempData}
            }
            else if(isEmpty(tempData) || typeof tempData[payload.sectionName]=='undefined'){
                tempData[payload.sectionName] = []
                tempData[payload.sectionName].push(payload.sectionData);
                state.table_add_form_data = {};
                state.tabel_form_data = {}
                state.tabel_form_data = {...tempData}
            }
        },

        GET_PAGE_DETAILS(state, mainpayload){
        
            let tempObj = {};
            let {secObjName} = state.active_menu;
            let payload = mainpayload[secObjName]
            let all_page_fields =  state.all_page_feilds
            state.tabel_form_data = {};
            if(payload){
                for(let formObj in payload){
                    
                    if(Array.isArray(payload[formObj])){
                        let temPsection = state.multi_section_page_fields.datas.find(list=>list.header.authorityCode==formObj);
                        if(temPsection){
                            if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='table' || temPsection.header.type=='addTable' || temPsection.header.type=='popupTable' || temPsection.header.type=='table_display')){
                                state.tabel_form_data[temPsection.header.authorityCode] = []
                                if((payload[formObj] && payload[formObj].length)){
                                    state.tabel_form_data[temPsection.header.authorityCode] = payload[formObj]
                                }
                                
                            }
                            else if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='reviewTable')){
                                temPsection && temPsection.body.forEach((formList)=>{
                                    let checkFields =  payload[formObj].find((list)=>formList.model.includes(list.code))
                                    if(checkFields){
                                        formList.weightage = checkFields.weightage
                                        tempObj[`${formList.model}`] = checkFields.score
                                    }
                                })
                            }
                        }
                        else{
                            let sectionFields = state.multi_section_page_fields.datas
                            if(sectionFields){
                                let tempField = sectionFields.find(list=>list.header.type=="multiple")
                                let tempIndex =  findIndex(sectionFields, (list)=>list.header.type=="multiple");
                                let tempFieldSet = []
                                let firstSet  = {};
                                let finalArray = [];
                                if(tempIndex && tempField){
                                    if(payload[formObj] && payload[formObj].length){
                                        firstSet = first(state.multi_section_page_fields.datas[tempIndex].body)
                                        for(let incForm = 0; incForm<payload[formObj].length; incForm++){
                                            tempFieldSet.push(firstSet)
                                        }
                                    }

                                    if(tempFieldSet && tempFieldSet.length){ 
                                        let temValueArray = [];
                                        payload[formObj].forEach((list)=>{
                                            let incVal = 0;
                                            for(let fieldSet in firstSet){
                                                let findTarget = list[last(firstSet[fieldSet].model.split('__'))]
                                                if(typeof temValueArray[incVal] == 'undefined'){
                                                    temValueArray[incVal] = {}
                                                }
                                                if(typeof temValueArray[incVal][firstSet[fieldSet].model] == 'undefined'){
                                                    temValueArray[incVal][firstSet[fieldSet].model] = []
                                                }
                                                temValueArray[incVal][firstSet[fieldSet].model].push(findTarget)
                                                incVal++;
                                            }
                                        })

                                        finalArray = temValueArray
                                        state.multi_section_pannel_form_data = finalArray
                                        state.multi_section_page_fields.datas[tempIndex].body = tempFieldSet
                                    }
                                }
                            }
                        }
                    }
                    else if(typeof payload[formObj]=='object'){
                        for(let subObj in payload[formObj]){
                            let checkType = all_page_fields.find(list=>list.model==`${formObj}__${subObj}` && list.type.includes('date'))
                            if(checkType){
                                tempObj[`${formObj}__${subObj}`] = convert_datetoiso(payload[formObj][subObj])
                            }
                            else{
                                tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj]
                            }
                        }
                    }
                    else{
                        let checkType = all_page_fields.find(list=>list.model==formObj && list.type.includes('date'))
                        if(checkType){
                            tempObj[`${formObj}`] = convert_datetoiso(payload[formObj])
                        }
                        else{
                            tempObj[`${formObj}`] = payload[formObj]
                        }
                    }
                }
                
            }
            else{
                tempObj = {}
                for(let formList in mainpayload){
                    if(!Array.isArray(mainpayload[formList]) &&  (typeof mainpayload[formList]!='object')){
                        tempObj[formList] = (mainpayload[formList]) ? mainpayload[formList] : ''
                    }
                }
            }
            let _multiText = all_page_fields.filter(list=>list.type.includes('multi_'))
            if(_multiText && _multiText.length){
                _multiText.forEach((list)=>{
                    if(payload && !payload[list.model]){
                        tempObj[list.model] = []
                    }
                    else {
                        tempObj[list.model] = payload[list.model]
                    }
                })
            }
            
            state.multi_section_form_data = tempObj
        },

        GET_LOOKUP_VALUES(state,payload){
            let {responseData,apiRequest} = payload
            if(responseData && responseData.lookupTypeValues &&responseData.lookupTypeValues.length){    
                let lookupValues = orderBy(responseData.lookupTypeValues, ['orderNo'], ['asc'])
                let values = lookupValues.map((list)=>{
                    return{
                        text : list.value,
                        value : list.value,
                        code: list.value,
                    }
                }) 
                if(apiRequest.requests.fieldName && apiRequest.requests.fieldType=='select'){
                    state.lookupby_type = [ ...values]
                }
                else if(apiRequest.requests.fieldType=='select'){
                    state.lookupby_type = values
                }
                else{
                    state.lookupby_type = values
                }
            }   
            else if(payload && payload.lookupTypeValues &&payload.lookupTypeValues.length){    
                let lookupValues = orderBy(payload.lookupTypeValues, ['orderNo'], ['asc'])
                let values = lookupValues.map((list)=>{
                    return{
                        text : list.value,
                        value : list.value,
                        code: list.value,
                    }
                }) 
                state.lookupby_type = values
            }        
            else{
                state.lookupby_type = [];
            }
        },
        UPDATE_SEARCHCLAIM_DETAILS(state, payload){
            let searchclaimDetails = {...state.searchclaim_details}
            searchclaimDetails[payload.state_name] = payload.value;
            state.searchclaim_details = searchclaimDetails
        },
        GET_MASTER_LOOKUP(state, payload){
            let {responseData,apiRequest} = payload
            if(responseData && responseData.length){  
                let fieldsDetails  = orderBy(responseData, ['orderNo'],['asc']); 
                let values = []
                if(apiRequest && apiRequest.requests && apiRequest.requests.code=='INVESTIGATION_OFFICER_NAME') {
                
                    values = fieldsDetails.map((list)=>{
                        return{
                            text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.email,
                            value : (list.userId) ? list.userId.trim()  : (list.userId) ? list.userId.trim()  : list.userId,
                            code: (list.code) ? list.code.trim()  : (list.code) ? list.code.trim()  : list.code,
                        }
                    }) 
                }
                else if(apiRequest && apiRequest.url.includes('/fetchCaseStatus')){
                    let tempList = fieldsDetails.map((list)=>{
                        return{
                            text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                            value : (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                            code: (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        }
                    })   
                    values = [{text:'-- Select a Status --', value : '', code: ''}, ...tempList];
                }
                else{
                    
                    values = fieldsDetails.map((list)=>{
                        return{
                            text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                            value : (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                            code: (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        }
                    })   
                }

                if(apiRequest && apiRequest.url.includes('/fetchCaseStatus')){
                    state.case_status = fieldsDetails
                }

            
                    
                if(apiRequest.requests.fieldType=='select'){
                    state.lookupby_type = values
                }
                else{
                    state.lookupby_type = values
                }
            }     
            else if(payload && payload.length){
                state.lookupby_type = payload.map((list)=>{
                    return{
                        text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        value : (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        code: (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                    }
                })   
            }   
            else{
                state.lookupby_type = [];
            }
        },

        UDPATE_CLAIM_REF(state, payload){
            state.claim_ref_number = payload
        }
    }
    
}

export default VendorPayment;